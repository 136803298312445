$el: '.CvoModal';

.ModificationOnCommitmentNotAllowedModal {
  text-align: center;

  .vm--modal {
    max-width: 100%;
    @media screen and (max-width: 480px) {
      left: 0 !important;
    }
  }

  #{$el}-title {
    h1 {
      @include text-xl-figma;
      font-weight: 500;
      color: $primary-dark;
      margin-bottom: 42px;
      display: inline-block;
      &::before {
        content: '';
        margin-#{$end}: 12px;
        background-color: $primary-dark;
        mask-image: url($icons-sprite + '#information');
        display: inline-block;
        height: 32px;
        width: 32px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: cover;
        transform: translateY(6px);
      }
    }
    button {
      display: none;
    }
  }

  #{$el}-content {
    padding: 0px 40px;
    h2 {
      @include text-m-figma;
      font-weight: 500;
      margin-bottom: 24px;
    }
    div p {
      @include text-m-figma;
      line-height: 24px;
    }

    button {
      margin: 44px auto 32px;
      padding: 16px 40px;
    }
  }
}
