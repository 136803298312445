.TripDisplay {
  &-node {
    display: flex;
    flex-direction: column;
    margin: auto;

    @include mobile {
      margin: unset;
      margin-bottom: 12px;
    }

    &:last-child {
      text-align: $end;

      @include mobile {
        text-align: unset;
      }
    }
  }
}