$el: ".DropdownMenu";

.DropdownMenu {
  &-wrapper {
    position: relative;
  }

  &-listWrapper {
    @include menu-appear;

    overflow: hidden;
    position: absolute;
    #{$end}: 0;
    background-color: $white;
    z-index: 1;
  }

  &-list {
    margin: 0;
    padding: 0;
  }

  &-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.is-open {
      background-color: unset;
    }
  }

  &-item {
    cursor: pointer;
    min-height: 10px;
    white-space: nowrap;
    padding: $container-padding-sm;
    display: flex;
    align-items: center;
    justify-content: $start;
    gap: $container-padding-sm;

    &:not(:first-child) {
      border-top: 1px solid $gray-lighter;
    }

    &:hover {
      background-color: $gray-lighter;
    }
  }

  &-mobileHeader {
    display: none;
  }

  @include mobile {
    &-listOverlay {
      @include overlay;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
    }

    &-listWrapper {
      margin-top: 0;
      @include slide-up($transition-duration-xl);

      width: 100%;
      height: auto;
      background-color: $white;
    }

    &-mobileHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $container-padding-sm;
    }

    &-item {
      border-top: 1px solid darkgrey;
    }

    &-buttonText {
      display: none;
    }
  }

  &-closeButton {
    @include mobile {
      height: $button-size;
      padding: 0;
      width: $button-size;

      .ActionButton-iconBefore {
        margin: auto;
      }
    }
  }
}