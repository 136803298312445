.CvoTiles {
  &-title {
    margin-bottom: 56px;
    text-align: center;
  }

  &-tileImage  {
    height: 40px;
    width: 40px;

    &::before {
      background-color: $primary-dark;
      height: 42px;
      mask-position: center;
      mask-size: cover;
      width: 42px;
    }
  }

  &-tileText {
    @include text-m-figma;
    line-height: 26px;
  }
}

.CvoTiles--benefits {
  .CvoTiles {
    &-container {
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    &-tile {
      padding: 0 24px 28px;
      max-width: calc(100% / 3);
      min-width: calc(100% / 3);
      border-#{$end}: 1px solid $gray-light;

      @include mobile {
        border-#{$end}: none;
        max-width: 100%;
        min-width: 100%;
      }

      &:nth-child(3),
      &:nth-child(6) {
        border-#{$end}: none;
      }
    }

    &-imageContainer {
      flex: none;
      margin-#{$end}: 12px;
    }

    &-textContainer {
      justify-content: flex-start;
    }

    &-tileText {
      margin: auto 0;
    }
  }
}

.CvoTiles--steps {
  .CvoTiles {
    &-container {
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    &-tile {
      border-#{$end}: 1px solid $gray-light;
      max-width: calc(100% / 2);
      min-width: calc(100% / 2);

      @include mobile {
        border-#{$end}: none;
        flex-wrap: wrap;
        margin-bottom: 36px;
        max-width: 100%;
        min-width: 100%;
      }

      &:nth-child(2),
      &:nth-child(4) {
        border-#{$end}: none;
        padding-#{$start}: 48px;

        @include mobile {
          padding-#{$start}: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        padding-#{$end}: 24px;

        @include mobile {
          padding-#{$end}: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        padding-bottom: 23px;

        @include mobile {
          padding-bottom: 0;
        }
      }

      &::before {
        @include h1-figma;

        align-self: center;

        @include mobile {
          display: block;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 12px;
          min-width: 100%;
          text-align: center;
        }
      }

      $steps: 1 2 3 4;
      @for $i from 1 through length($steps) {
        &:nth-child(#{$i})::before {
          content: '#{$i}';
          min-width: 30px;
        }
      }
    }

    &-imageContainer {
      flex: none;
      margin: 0 32px;

      @include mobile {
        margin-#{$start}: 0;
      }
    }

    &-textContainer {
      justify-content: flex-start;
    }

    &-tileText {
      margin: auto 0;
    }
  }
}

