.CvoSelectableList {
  position: relative;

  &-button {
    $icon-size: 30px;

    @include button-outline;

    border-color: $gray-light;
    padding: 6px;
    padding-#{$end}: calc(16px + #{$icon-size});
    padding-#{$start}: 16px;
    position: relative;

    &::after {
      content: '';
      background-color: $primary-dark;
      bottom: 0;
      content: '';
      display: inline-block;
      mask-image: url($icons-sprite + '#chevron-down');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 150%;
      position: absolute;
      top: 0;
      transition: $transitionAll;
      width: $icon-size;
      #{$end}: 5px;
    }

    &.is-open::after {
        mask-image: url($icons-sprite + '#chevron-up')
    }
  
    &:hover,
    &:active,
    &:focus {
      background: none;
      background-color: $white;
      border-color: $gray-light;
      color: $primary-darker;
    }
  }

  &-listWrapper {
    background: $white;
    border: 1px solid $gray-light;
    margin-top: 5px;
    min-width: 100%;
    padding: 16px;
    position: absolute;
    white-space: nowrap;
    #{$start}: 0;
  }
  
  .CustomLanguageButton,
  .CustomLanguageItem {
    span {
      @include text-s-figma;
      
      font-weight: 500;
      transition: $transitionAll;
    }

    .language {
      color: $primary-dark;
    }
  }
}