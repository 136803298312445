.SlideMenu {
  &-panelWrapper {
    background: #ffffff;
    display: none;
    height: 100vh;
    height: 100dvh;
    left: 0;
    min-width: 30vw;
    position: fixed;
    top: 0;

    &.is-open {
      display: block;
    }
  }

  &-panel {
    display: flex;
    flex-direction: column;
  }

  &-closeButton {
    margin-left: auto;
  }

  .Overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

[dir="rtl"] {
  .SlideMenu {
    &-panelWrapper {
      left: unset;
      right: 0;
    }

    &-closeButton {
      margin-left: unset;
      margin-right: auto;
    }
  }
}