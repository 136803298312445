.CvoSlideMenu {
  display: none;

  @include mobile {
    display: block;
  }

  &-menuButton {
    background-color: $primary-dark;
    height: 42px;
    mask-image: url($icons-sprite + '#hamburger-menu');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 150%;
    width: 42px;
  }

  &-panelWrapper {
    background-color: $primary-dark;
    display: block;
    max-width: 0;
    min-width: 0;
    overflow: hidden;
    transition: $transitionAll;

    &.is-open {
      max-width: 100vw;
      min-width: 100vw;
    }
  }

  &-panel {
    min-width: 50vw;
    padding: 20px;
    height: 100%;
  }

  &-panelSection {
    display: flex;
    flex-direction: column;

    > h2 {
      @include h4-figma;
      color: $white;
      font-weight: 700;
      margin-bottom: 12px;
    }

    >a {
      color: $white;
      margin-#{$end}: auto;
      margin-bottom: 8px;
      text-decoration: none;
    }
  }

  &-closeButton {
    background-color: $white;
    height: 32px;
    margin-left: unset; // reset library default style;
    margin-#{$start}: auto;
    mask-image: url($icons-sprite + '#close');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 150%;
    width: 32px;
  }

  &-powered {
    display: inline-block;
    margin-#{$end}: 5px;
  }

  &-powered,
  &-poweredLink {
    @include text-s-figma;

    color: $white;
    font-weight: 500;
  }

  & &-poweredLink {
    margin-bottom: 0;

    svg {
      transform: scale(1.5) translateX(10px * $transform-to-end);
    }
  }

  &-footer {
    align-items: center;
    flex-direction: row;
    margin-top: auto;
  }
}