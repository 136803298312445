.StepsBar {
  &--simple {
    .StepsBar {
      &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 14px;
        justify-content: space-around;
        list-style-type: none;
        
        @include mobile {
          flex-direction: column;
        }
      }

      &-item {
        &.is-active {
          font-weight: bold;
        }

        &--hasIcon {
          display: flex;
          align-items: center;

          .StepsBar-iconWrapper {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &--withMarkers {
    .StepsBar {
      &-list {
        display: flex;
        text-align: center;
        
        @include mobile {
          flex-direction: column;
        }
      }

      &-segment {
        flex-basis: 12px;
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        
        &:not(:last-child):after {
          content: "";
          display: block;
          background-color: $primary;
          position: absolute;
          height: 8px;
          left: 50%;
          right: -50%;
          top: 12px;

          @include mobile {
            bottom: -8px;
            left: 12px;
            height: auto;
            top: 16px;
            width: 8px;
          }
        }

        &.is-active {
          &::after {
            background-color: lighten($primary, 50%);
          }

          .StepsBar-marker {
            background-color: $secondary;
            color: $white;
          }
        }

        &.is-active ~ .StepsBar-segment{
          &::after {
            background-color: lighten($primary, 50%);
          }
          
          .StepsBar-marker {
            background-color: lighten($primary, 50%);
            color: $primary;
            
            .subs-icons {
              &::before {
                background-color: $primary;
              }
            }
          }
        }

        @include mobile {
          display: flex;
          flex-grow: 1;
          min-height: 64px;
        }
      }

      &-marker {
        background-color: $primary;
        color: $white;
        left: calc(50% - 12px);
        position: absolute;
        height: 32px;
        width: 32px;
        overflow: hidden;
        align-items: center;
        display: flex;
        border-radius: 50%;
        font-weight: 700;
        justify-content: center;
        z-index: 5;

        @include mobile {
          position: relative;
          left: unset;
        }
      }

      &-content {
        margin-top: 32px;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 6px;

        @include mobile {
          margin-top: 0;
        }
      }
    }

    .subs-icons {
      &::before {
        background-color: $white;
      }
    }
  }
}

[dir="rtl"] {
  .StepsBar {
    &--withMarkers {
      .StepsBar {
        &-segment:not(:last-child):after {
          left: -50%;
          right: 50%;

          @include mobile {
            left: unset;
            right: calc(1rem - (0.2em));
          }
        }
      }
    }

    &--simple {
      .StepsBar {
        &-item {
          &--hasIcon {
            .StepsBar-iconWrapper {
              margin-left: 5px;
              margin-right: unset;
            }
          }
        }
      }
    }
  }
}