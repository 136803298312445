.RegisterPrivate {
  &-headerContainer {
    background-color: $primary-lighter;
  }

  &-formContainer {
    @extend .pb-m-3, .px-m-3;
    background-color: $primary-lighter;
  }

  &-submit {
    display: block;
    margin: 0 auto;

    @include mobile {
      display: none;
    }
  }

  &--soloPlan {
    .CvoFormBuilder-description {
      .Notice {
        display: none;
      }
    }
  }

  .CvoInputField.email,
  .CvoInputField.password {
    &::after {
      background-color: $success;
      bottom: 14px;
      content: '';
      display: block;
      height: 30px;
      mask-image: url($icons-sprite + '#check');
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      position: absolute;
      width: 30px;
      #{$end}: 14px;
    }
  }
}

[dir="rtl"] {
  .RegisterPrivate {
    .CvoFormBuilder {
      // custom mq to fix arabic issues
      @media screen and (max-width: 1090px) {
        &-formElement {
          width: 100%;
        }
      }
    }
  }
}
