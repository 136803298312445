.CarouselSlider {
  &-carousel {
    position: relative;
    background-color: lightgray;
    padding: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-viewport {
    overflow: hidden;
    width: 100%;

    &.is-draggable {
      cursor: move;
      cursor: grab;
    }

    &.is-dragging {
      cursor: grabbing;
    }
  }

  &-container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }

  &-slide {
    position: relative;
    min-width: 100%; // Overwritten by prop slidesToShow
    padding-left: 10px;

    &.is-2 {
      min-width: 50%;
    }

    &.is-3 {
      min-width: calc(100% / 3);
    }

    &.is-4 {
      min-width: 25%;
    }

    &.is-5 {
      min-width: 20%;
    }

    &.is-6 {
      min-width: calc(100% / 6);
    }

    &.is-7 {
      min-width: calc(100% / 7);
    }

    &.is-8 {
      min-width: calc(100% / 8);
    }
  }

  &-slideInner {
    position: relative;
    overflow: hidden;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &-slideImg {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }

  &-slideText {
    background: white;
    z-index: 1;
  }

  &-control {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: gray;
    padding: 0;

    &:disabled {
      cursor: default;
      opacity: 0.3;
    }

    &--prev {
      left: 27px;
    }

    &--next {
      right: 27px;
    }
  }

  &-controlSvg {
    width: 100%;
    height: 100%;
  }

  &-navigation {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 10px;
  }

  &-navigationButton {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;

    &:after {
      background-color: lightgray;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      content: "";
    }

    &.is-selected:after {
      background-color: gray;
      opacity: 1;
    }
  }
}

[dir="rtl"] {
  .CarouselSlider {
    &-control {
      &--prev {
        left: unset;
        right: 27px;
        transform: rotate(180deg);
      }

      &--next {
        left: 27px;
        right: unset;
        transform: rotate(180deg);
      }
    }
  }
}