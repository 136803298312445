.SubscriptionMenu {
  .CvoDropdownMenu{
    &-wrapper {
      position: relative;
    }

    &-listWrapper {
      position: absolute;
    }

    &-buttonText {
      @include mobile {
        display: none;
      }
    }
  }
}