.MiniCart {
  display: inline-block;

  &-summary {
    display: flex;
    align-items: baseline;

    & > div {
      margin-right: 10px;
    }
  }

  &-summaryTotal {
    font-size: 18px;
    font-weight: bold;
  }

  &-expandButton {
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &-summaryArrow {
    width: 10px;
    height: 10px;
    display: inline-block;
    position: relative;
    bottom: -5px;
    left: -10px;
    transition: 0.4s ease;
    margin-top: 2px;
    text-align: left;
    transform: rotate(45deg);

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-block;
      width: 9px;
      height: 2px;
      background-color: #000;
      transition: 0.3s ease;
    }

    &:after {
      position: absolute;
      transform: rotate(90deg);
      top: -5px;
      left: 5px;
    }

    &.is-active {
      transform: rotate(45deg) translate(-5px, -5px);

      &:before {
        transform: translate(10px, 0);
      }

      &:after {
        transform: rotate(90deg) translate(10px, 0);
      }
    }
  }

  &-panel {
    display: none;
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
    min-width: 250px;

    &--static {
      position: static;
    }

    &.is-off-screen {
      right: 0;
    }
  }

  &-section {
    display: flex;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    padding: 5px;

    &:last-child {
      flex-direction: column;
      border: none;
    }
  }

  &-sectionTripDisplay {
    flex-direction: column;
  }

  &-itemList {
    flex: 1;
  }

  &-itemWrapper {
    display: flex;
    justify-content: space-between;
  }

  &-itemNameDescription {
    margin-right: 10px;
  }

  &-itemName,
  &-itemDescription {
    display: block;
  }

  &-modalButton {
    align-self: flex-end;
  }

  &.is-expanded {
    .MiniCart-summaryArrow {
      transform: rotate(45deg) translate(-5px, -5px);

      &:before {
        transform: translate(10px, 0);
      }

      &:after {
        transform: rotate(90deg) translate(10px, 0);
      }
    }

    .MiniCart-panel {
      display: block;
    }
  }
}

[dir="rtl"] {
  .MiniCart {
    &-summary {
      & > div {
        margin-left: 10px;
        margin-right: unset;
      }
    }

    &-summaryArrow {
      direction: ltr;
    }

    &-itemNameDescription {
      margin-left: 10px;
      margin-right: unset;
    }

    &-panel {
      &.is-off-screen {
        left: 0;
        right: unset;
      }
    }
  }
}
