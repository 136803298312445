@import 'variables.scss';

@mixin fade-background($from: "", $to: "", $duration: 0.5s, $timing-function: linear) {
  @keyframes fadeBg {
    from { background-color: $from; }
    to { background-color: $to; }
  }
  animation: fadeBg $duration $timing-function;
};

@mixin overlay($top: 0, $z-index: 2) {
  @include fade-background(rgba($gray-dark, 0), rgba($gray-dark, 0.5));
  
  width: 100%;
  background-color: rgba($gray-dark, 0.5);
  height: 100%;
  position: fixed;
  left: 0;
  top: $top;
  z-index: $z-index;
}

@keyframes slide-up {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
@mixin slide-up($duration: $transition-duration) {
  animation: slide-up $duration linear;
}

@keyframes menu-appear {
  from {
    opacity: 0;
    transform: translateY(12px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@mixin menu-appear($duration: $transition-duration-sm) {
  animation: menu-appear $duration linear;
}

@mixin grow($duration: $transition-duration-sm) {
  animation: grow $duration linear;
}


@keyframes grow {
  from {
    grid-template-rows: 0fr;
    opacity: 0;
  }
  to {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}