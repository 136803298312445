.CustomizePlan {
  display: flex;
  gap: 36px;

  .Customize-submit {
    display: block;
    margin: 96px auto 0;

    @include mobile {
      display: none;
    }
  }

  >.PageContent {
    flex-basis: 70%;
    width: 70%;
  }

  >.CustomizePlan-cartContainer {
    flex-basis: 30%;
    width: 30%;
  }

  > .TwoColumns-mainContent > h1 {
    @include h2-figma;
  }

  .mb-6 {
    line-height: 25px;
  }
}
