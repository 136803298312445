@mixin form-page {
  &-headerContainer {
    background-color: $primary-lighter;
  }

  &-formContainer {
    background-color: $primary-lighter;
  }

  &-submit {
    display: block;
    margin: 0 auto;

    @include mobile {
      display: none;
    }
  }
}