.CvoSimpleFooter {
  flex-direction: column;
  gap: 24px;
  padding-bottom: 30px;
  padding-top: 30px;

  &-row {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-flow: column;
    }
  }

  &-copyright {
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      margin-bottom: 24px;
    }

    span {
      display: flex;
      gap: 5px;

      @include mobile {
        align-items: center;
        flex-direction: column;
      }

      img {
        max-width: 90px;

        @include mobile {
          margin-top: 12px;
        }
      }
    }
  }

  &-infoWrapper {
    display: flex;
    flex-flow: column;
    align-items: end;
    gap: 8px;

    @include mobile {
      align-items: center;
    }
  }

  p {
    text-align: center;
    line-height: 2.5;
    max-width: 100%;

    > span {
      border-#{$end}: 1px solid $gray;
      padding: 0px 8px;

      @include mobile {
        display: block;
        border-#{$end}: unset;
        line-height: 1.8;
        padding: unset;
      }

      &:first-child {
        display: block;
        border-#{$end}: unset;
        padding-#{$end}: unset;
      }

      &:last-child {
        border-#{$end}: unset;
        padding-#{$end}: unset;
      }
    }
  }
}

.Footer {
  background-color: $white;
  box-shadow: $box-shadow;
  @include mobile {
    padding: 0 20px;
  }

  @include tablet {
    padding: 0 32px;
  }

}
