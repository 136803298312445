.CvoPurchaseConfirmation {
  &-background {
    padding: 24px 24px 42px;
    background-size: cover;

    @include mobile {
      background: none !important; // override inline;
      padding: 0;
    }
  }

  &-container {
    background-color: $white;
    border-radius: $border-radius;
    margin: auto;
    max-width: 558px;
    padding: 24px;

    @include mobile {
      border: 1px solid $gray-light;
    }
  }

  &-successMessage {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;

    &::before {
      background-color: $success;
      content: '';
      height: 24px;
      margin-#{$end}: 12px;
      mask-image: url($icons-sprite + '#check');
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: cover;
      width: 24px;
    }
  }

  &-text {
    margin-bottom: 24px;
    text-align: center;
  }

  &-extraInfo {
    margin-bottom: 24px;
    text-align: center;
  }

  &-price {
    display: none;
  }

  &-action {
    display: block;
    margin: 0 auto 24px;
  }

  &--multi-plan {
    .CvoPurchaseConfirmation-action {
      @include button-icon-before('plus', $primary-darker, $white, $primary-darker);
    }
  }

  &--single-plan {
    .CvoPurchaseConfirmation-action {
      @include button-icon-after('chevron-right', $primary-darker, $white, $primary-darker);
    }
  }

  &-summaryList {
    strong {
      font-weight: 500;
    }

    .Badge {
      text-transform: uppercase;
      
      @include mobile {
        display: block;
        width: max-content;
      }
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $gray-light;
    padding: 0;
  }
}