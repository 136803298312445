$card-padding: 20px;

.CvoInformationCard {
  background: $white;
  padding: $card-padding;
  border: 1px solid $gray-light;
  max-width: 867px;
  margin: auto;

  &-title {
    margin-bottom: 20px;
  }

  &-content {
    margin-bottom: 12px;
  }

  &-extrasWrapper>div:first-child {
    text-align: unset;
  }

  &-action {
    @include mobile {
      display: none;
    }
  }

  &-actionMobile {
    width: 100%;
  }

  &-secondaryAction {
    @include mobile {
      top: $card-padding;
      #{$end}: $card-padding;
      #{$start}: auto;
    }
  }
}

$el: '.CvoInformationCard';
.CvoInformationCard--yourPlan {
  position: relative;

  p#{$el}-content:last-of-type {
    margin-top: 24px;
  }

  p#{$el}-content:nth-of-type(2)::first-letter {
    text-transform: capitalize;
  }

  #{$el}-action {
    bottom: $card-padding;
    position: absolute;
    #{$end}: $card-padding;
  }

  #{$el}-secondaryAction {
    display: flex;
    justify-content: end;
  }
}

.CvoInformationCard--paymentCard {
  .CvoInformationCard {
    &-informationWrapper {
      p {
        padding-#{$end}: 25px;
        margin-top: 20px;
      }

      .CvoInformationCard {
        &-content {
          h2 {
            font-size: 1.2rem;
            color: black;
          }
        }
      }
    }

    &-extrasWrapper {
      .CvoInformationCard {
        &-secondaryAction {
          border: none;
          background-color: unset;
          background-image: unset;
          color: $primary-dark;
          font-weight:normal;
          display: flex;
          align-items: center;
          height: unset;
          margin-bottom: 10px;
          padding-top: 0px;
          gap: 8px;
          &::before {
            content: '';
            background-color: $primary-dark;
            mask: url($icons-sprite + '#change') no-repeat center;
            width: 22px;
            height: 15px;
            transform: scale(1.9);
            mask-position: center;
          }
        }
      }
    }

    &-extrasWrapper>div:first-child {
      display: flex;
      justify-content: $end;
    }
  }

  .DefinitionListWrapper {
    padding: 25px;
    border: 1px solid $gray-lighter;

    > h2 {
      color: $primary-light;
      font-size: 20px;
    }

    > h2::before {
      content: '';
      display:inline-block;
      background-color: $primary-light;
      mask: url($icons-sprite + '#credit-card') no-repeat center;
      margin-#{$end}: 5px;
      width: 22px;
      height: 15px;
      transform: scale(1.9);
      mask-position: center;
    }
  }
}
