// Adding more sizes to bulma classes eg. mb-7, mb-8, mb-9
$sizes: 4rem, 5rem, 6rem;
@for $i from 1 through length($sizes) {
  $n: $i + 6; // $n should start from 6 as last bulma class is 6 eg. mb-6

  $directions: '', 't', 'r', 'b', 'l';
  $dir: '', '-top', '-right', '-bottom', '-left';
  
  @for $j from 1 through length($directions) {
    // Margin
    .m#{nth($directions, $j)}-#{$n} {
      margin#{nth($dir, $j)}: nth($sizes, $i) !important;
    }

    // Padding
    .p#{nth($directions, $j)}-#{$n} {
      padding#{nth($dir, $j)}: nth($sizes, $i) !important;
    }
  }

  .mx-#{$n} {
    margin-left: nth($sizes, $i) !important;
    margin-right: nth($sizes, $i) !important;
  }

  .my-#{$n} {
    margin-bottom: nth($sizes, $i) !important;
    margin-top: nth($sizes, $i) !important;
  }

  .px-#{$n} {
    padding-left: nth($sizes, $i) !important;
    padding-right: nth($sizes, $i) !important;
  }

  .py-#{$n} {
    padding-bottom: nth($sizes, $i) !important;
    padding-top: nth($sizes, $i) !important;
  }
}

// For desktop view use bulma classes that are included with cvo-library styles
$sizes: 0, 0.25rem, 0.5rem, 0.75rem, 1rem, 1.5rem, 3rem, 4rem, 5rem, 6rem;
$mobile: 'm';

@include mobile {
  @for $i from 1 through length($sizes) {
    $n: $i - 1;
  
    $directions: '', 't', 'r', 'b', 'l';
    $dir: '', '-top', '-right', '-bottom', '-left';
  
    @for $j from 1 through length($directions) {
       // Margin
      .m#{nth($directions, $j)}-#{$mobile}-#{$n} {
        margin#{nth($dir, $j)}: nth($sizes, $i) !important;
      }

      // Padding
      .p#{nth($directions, $j)}-#{$mobile}-#{$n} {
        padding#{nth($dir, $j)}: nth($sizes, $i) !important;
      }
    }

    .mx-#{$mobile}-#{$n} {
      margin-left: nth($sizes, $i) !important;
      margin-right: nth($sizes, $i) !important;
    }

    .my-#{$mobile}-#{$n} {
      margin-bottom: nth($sizes, $i) !important;
      margin-top: nth($sizes, $i) !important;
    }

    .px-#{$mobile}-#{$n} {
      padding-left: nth($sizes, $i) !important;
      padding-right: nth($sizes, $i) !important;
    }

    .py-#{$mobile}-#{$n} {
      padding-bottom: nth($sizes, $i) !important;
      padding-top: nth($sizes, $i) !important;
    }
  }
}

// Helpers
.m-auto {
  margin: auto;
}