$amination-width: 200px;

.Booking {
  &-row {
    display: flex;
    gap: 12px;

    .TripDisplay {
      flex: 1;
    }
  }

  &Loader {
    background: rgba(255, 255, 255, 0.80);
    bottom: 0;
    display: flex;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;

    &-inner {
      margin: auto;
      display: flex;

      @include mobile {
        flex-direction: column;
      }
    }

    &-node {
      &--origin {
        text-align: $start;

        @include mobile {
          text-align: center;
        }
      }

      &--destination {
        text-align: $end;

        @include mobile {
          text-align: center;
        }
      }
    }

    &-nodeKey {
      display: block;
    }

    &--nodeName {
      color: $gray-dark;
    }

    &-animation {
      width: $amination-width;

      @include mobile {
        padding: 0 20px;
        height: 200px;
        padding-#{$end}: 24px;
        transform: rotate(90deg) translateY(90px);
        width: 200px;
      }
    }

    &-animation {
      position: relative;

      &::before {
        animation: plane-segment 1s ease-in-out infinite;
        background-color: $primary-light;
        content: '';
        display: inline-block;
        height: 3px;
        width: 100%;
        max-width: calc(100% - 24px); // icon width
      }
    }

    &-icon {
      animation: plane 1s ease-in-out infinite;
      position: absolute;
      #{$start}: 0;
      top: 0;

      @include mobile {
        #{$start}: 20px;
      }

      &::before {
        background-color: $primary;
        content: '';
        display: inline-block;
        height: 24px;
        transform: rotate(45deg);
        width: 24px;
        #{$start}: 0;
      }
    }
  }
}

@keyframes plane-segment {
  0% {
    transform: scaleX(0);
    transform-origin: $start;
  }

  100% {
    transform: scaleX(1);
    transform-origin: $start;
  }
}

@keyframes plane {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(#{$amination-width} - 24px));
  }
}