// Colors
$white: #FFFFFF;

$gray-lightest: #F8F8F8;
$gray-lightest-alt: #FDFDFD;
$gray-lighter: #E1E0E0;
$gray-lighter-alt: #E2E2E2;
$gray-light: #D4D2CF;
$gray: #6C757D;
$gray-dark: #55565A;
$gray-darker: #071C2C;

$primary-lighter: #D9E9F3;
$primary-light: #0D84C7;
$primary: #0D84C7;
$primary-dark: #004A97;
$primary-darker: #051583;

$primary-highlight: #479FDA;
$primary-gradient: linear-gradient(180deg, #184B92 0%, #001C83 100%);

$secondary-lighter: #FFCE00;
$secondary-light: #FDC12A;
$secondary: #FBCE40;

$success: #419059;
$warning: #FECE00;
$danger: #E24F4F;

// Borders
$border-radius: 10px;

// Fonts
$bukra: '29LT Bukra', sans-serif;
$arial: 'Arial', sans-serif;

// Icons
$icons-sprite: $s3-base-url + '/images/sprite.css.svg';
$green-icon: hue-rotate(260deg) brightness(3.75);
$primary-dark-icon: brightness(0) saturate(100%) invert(17%) sepia(73%) saturate(3146%) hue-rotate(198deg) brightness(89%) contrast(104%);
$secondary-icon: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(6009%) hue-rotate(314deg) brightness(80%) contrast(104%);
$success-icon: brightness(0) saturate(100%) invert(52%) sepia(20%) saturate(990%) hue-rotate(86deg) brightness(89%) contrast(93%);

// Size
$tablet: 769px !default;
$desktop: 1280px !default;

// Transitions
$transitionAll: all 300ms ease-in-out;

// Shadows
$box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);
$drop-shadow: 1px 4px 26px 3px rgba(0, 0, 0, 0.08);
