.Addon {
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;

  @include mobile {
    flex-direction: column;
  }

  &-labelWrapper {
    position: absolute;
    top: 12px;
    #{$start}: 12px;
  }

  &-contentWrapper {
    flex: 1;
  }

  &-actionsWrapper {
    display: flex;
  }

  &-controlWrapper {
    .Checkbox {
      pointer-events: none;
    }
  }
}