.PasswordField {
  &-inner {
    position: relative;
  }

  input.help {
    margin-top: 0;
  }

  &-toggleButton {
    padding: 4px;
    position: absolute;
    right: 0;


    &::before {
      opacity: 0.3;
      transition: opacity .3s;
    }

    &.is-unmasked {
      &::before {
        opacity: 1;
      } 
    }
  }
}
