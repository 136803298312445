$dropImage: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');

.Autocomplete {
  &-inputWrapper {
    position: relative;
  }

  &-clearButton {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  input.help {
    margin-top: 0;
  }

  input {
    padding-left: 40px;
    position: relative;
    background-image: $dropImage;
    background-repeat: no-repeat;
    background-position: 10px;
    appearance: none;
    align-items: center;
    border-radius: 4px;
    border: 1px solid transparent;
    border-color: #dbdbdb;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    position: relative;
    vertical-align: top;
    background-color: #fff;
    color: #363636;
    max-width: 100%;
    width: 100%;
    margin: 0;
    box-sizing: border-box;

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }

    &:disabled {
      color: #ccc;
      background-color: #f3f3f3;
      border-color: #ccc;
      cursor: not-allowed;
    }
  }

  [data-position="above"] input[aria-expanded="true"] {
    z-index: 2;
  }

  .autocomplete[data-loading="true"] {
    .Autocomplete-clearButton {
      visibility: hidden;
    }
  }

  .autocomplete[data-loading="true"]:after {
    content: "";
    border: 3px solid rgba(0, 0, 0, 0.12);
    border-right-color: rgba(0, 0, 0, 0.48);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }

  .autocomplete-result-list {
    margin: 0;
    border: 1px solid;
    padding: 0;
    box-sizing: border-box;
    max-height: 296px;
    overflow-y: auto;
    background: #fff;
    list-style: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  }

  [data-position="below"] .autocomplete-result-list {
    margin-top: -1px;
    border-top-color: transparent;
    padding-bottom: 8px;
  }

  [data-position="above"] .autocomplete-result-list {
    margin-bottom: -1px;
    border-bottom-color: transparent;
    padding-top: 8px;
  }

  .autocomplete-result {
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    padding: 10px 10px 10px 40px;
    background-image: $dropImage;
    background-repeat: no-repeat;
    background-position: 10px;
  }

  .autocomplete-result:hover,
  .autocomplete-result[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.06);
  }

  p.Form-field--error {
    margin: 0;
    padding: 0;
  }

  @keyframes rotate {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }
    to {
      transform: translateY(-50%) rotate(359deg);
    }
  }
}

[dir="rtl"] {
  .Autocomplete {
    input {
      padding-left: calc(0.75em - 1px);
      padding-right: 40px;
      background-position: right 10px center;
    }

    .autocomplete-result {
      text-align: right;
      padding: 10px 40px 10px 10px;
      background-position: right 10px center;
    }

    .autocomplete[data-loading="true"]:after {
      left: 12px;
      right: unset;
    }
  }
}
