.CvoRadio, .Radio  {
  &-controlsContainer {
    height: auto;

    .control {
      align-items: center;
      border: 0px;
      display: flex;
      margin-bottom: 20px;

      label {
        margin-#{$start}: 8px;
      }

      input {
        appearance: none;
        background-color: $white;
        border: 2px solid $primary-dark;
        border-radius: 50%;
        height: 23px;
        max-height: 23px;
        max-width: 23px;
        min-height: 23px;
        min-width: 23px;
        text-align: center;
        width: 23px;

        &:checked {
          border: 6px solid $primary-dark;
        }

        &[type="radio"] {
          #{$start}: unset;
          position: unset;
        }
      }
    }

    .control:last-child {
      margin-bottom: 0;
    }
  }
}
