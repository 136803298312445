.CvoStepsBar {
  margin: 20px auto 50px auto;

  @include desktop {
    width: $desktop;
  }

  &-simple {
    display: flex;
    flex-wrap: nowrap !important;

    @include mobile {
      flex-direction: row !important;
    }

    &-item {
      display: flex;
      color: $gray-lighter;
      border-top: 2px solid $gray-lighter;
      border-bottom: 2px solid $gray-lighter;
      border-#{$start}: 2px solid $gray-lighter;
      width: 100%;
      align-items: center;
      height: 56px;
      justify-content: center;
    }

    &-item:last-child {
      border-#{$end}: 2px solid $gray-lighter;
    }

    &-itemText {
      @include mobile {
        display: none;
      }
    }

    &-itemIndex {
      background-color: $primary-dark;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      padding: 5px;
      text-align: center;
      margin-#{$end}: 8px;
      font-weight: 500;
      color: $white;
      line-height: normal;
    }

    .is-active {
      background-color: $primary-dark;
      color: $white;

      ~ li .CvoStepsBar-simple-itemIndex {
        background-color: $gray;
      }

      .CvoStepsBar {
        &-simple {
          &-itemText {
            color: $white;
          }

          &-itemIndex {
            background-color: $white;
            color: $primary-dark;
          }
        }
      }
    }
  }
}