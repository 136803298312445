.ToggleButton {
  &-buttonsContainer {
    display: flex;
    justify-content: space-between;
  }

  &-label {
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color .3s;

    &.is-selected {
      border-color: #000;
    }

    &.is-disabled {
      background: whitesmoke;

      * {
        opacity: 0.5;
      }
    }
  }

  &-title {
    transition: color .3s;
  }

  &-radio {
    display: none;
  }

  @include mobile {
    flex-direction: column;
  }
}
