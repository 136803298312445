.Review {
  width: 250px;
  height: 191px;
  padding: 24px;
  border: 1px solid $gray-lighter;
  border-radius: 8px;
  box-shadow: $drop-shadow;
  text-align: center;

  &-score {
    display: flex;
    gap: 4px;
    margin-bottom: 24px;
    justify-content: center;

    .j9-icons.star::before {
      width: 35px;
      height: 35px;
      scale: 2;
      background-color: $secondary-light;
    }
  }

  &-comment {
    display: inline-flex;
    margin-bottom: 16px;

    p {
      @include text-s-figma;
    }
  }

  &-author {
    display: inline-flex;

    p {
      @include text-m-figma;
      color: $primary-dark;
      font-weight: 500;
    }
  }
}