@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@mixin blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@mixin fade-background ($from: "", $to: "", $duration: 0.5s, $timing-function: linear) {
  @keyframes fadeBg {
    from { background-color: $from; }
    to { background-color: $to; }
  }
  animation: fadeBg $duration $timing-function;
}