.Radio {
  .control:has([type="radio"]:disabled) {
    opacity: .5;
    pointer-events: none;
  }

  [type="radio"] {
    #{$start}: -9999px;
    position: absolute;

    + label {
      
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      position: relative;
      white-space: nowrap;

      &::before {
        background: $white;
        border-radius: 100%;
        content: "";
        height: 18px;
        margin-#{$end}: 10px;
        width: 18px;
        flex-shrink: 0;
      }

      &::after {
        opacity: 0;
        background: $white;
        border-radius: 100%;
        content: "";
        height: 8px;
        #{$start}: 5px;
        position: absolute;
        top: 8px;
        width: 8px;

        transition: all .3s;
      }
    }

    &:checked {
      + label {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}