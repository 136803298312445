.Checkbox {
  width: auto;

  &-wrapper {
    display: flex;
  }

  &-input {
    margin-right: 10px;
  }

  .label {
    display: inline-block;
    margin-bottom: 0;
  }

  input.help {
    display: inline-block;
    margin-top: 0;
  }
}

[dir="rtl"] {
  .Checkbox {
    &-input {
      margin-left: 10px;
      margin-right: unset;
    }
  }
}

@mixin custom-checkbox($background-color-on, $background-color-off, $border-color, $checkmark-color, $label-color) {
  $start: left !default;
  $end: right !default;
  $direction: 'ltr' !default;

  #{$start}: -9999px;
  opacity: 0;
  position: absolute;

  + label {
    cursor: pointer;
    display: inline-flex;
    position: relative;

    span {
      color: $label-color;
    }

    /* Box. */
    &::before {
      background-color: $background-color-off;
      border: 1px solid $border-color;
      border-radius: 4px;
      content: "";
      display: inline-block;
      height: 18px;
      margin-#{$end}: 10px;
      min-width: 18px;
      vertical-align: text-top;
      width: 18px;

      transition: background-color .1s;
    }

    /* Checkmark. Could be replaced with an image */
    &::after {
      opacity: 0;
      background: $checkmark-color;
      box-shadow: 2px 0 0 $checkmark-color, 4px 0 0 $checkmark-color, 4px -2px 0 $checkmark-color,
        4px -4px 0 $checkmark-color, 4px -6px 0 $checkmark-color, 4px -8px 0 $checkmark-color;
      content: "";
      height: 3px;
      #{$start}: if($direction == 'ltr', 6px, 14px);
      top: 10px;
      position: absolute;
      transform: rotate(45deg);
      width: 2px;

      transition: opacity .1s;
    }
  }

  /* Box checked */
  &:checked {
    + label {
      &::before {
        background: $background-color-on;
        border-color: $border-color;
      }

      /* Checkmark. Could be replaced with an image */
      &::after {
        opacity: 1;
      }
    }
  }

  @content;
}
