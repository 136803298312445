.CvoNavigation {
  margin-bottom: 30px; 

  &-nav {
    justify-content: unset;

    &.is-hidden-mobile {
      @include mobile {
        display: flex !important;
      }
    }
  }

  &-hamburgerWrapper {
    display: none !important;
  }

  &-item {
    align-items: center;
    border: 1px solid $gray-light;
    display: flex;
    flex: 1;
    font-weight: 500;
    justify-content: center;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:not(:last-child) {
      border-#{$end}: none;
    }

    &.is-active {
      background-color: $primary-dark;
      
      span {
        color: $white;
      }

      i {
        &::before {
          background-color: $white;
        }
      }
    }

    span {
      font-weight: 500;

      @include mobile {
        display: none;
      }
    }

    i {
      display: inline-block;
      margin-#{$end}: 5px;

      &::before {
        background-color: $gray-dark;
        mask-size: 120%;
      }
    }
  }
}