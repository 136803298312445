$el: '.CvoAddon';
@mixin selected-check-position {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  top: 40px;
  #{$start}: -20px;

  @include mobile {
    #{$start}: unset;
    #{$end}: 30px;
    top: 15px;
  }
}

.CvoAddon {
  width: 640px;
  border: 1px solid $primary-dark;
  border-radius: 20px;
  padding-#{$start}: 20px;
  position: relative;

  @include mobile {
    width: 100%;
    padding-#{$start}: 0px;
  }

  &-wrapper {
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &-imageWrapper {
    width: 60px;
    padding-top: 20px;

    img {
      padding: 7px;
      max-width: unset;
      filter: $primary-dark-icon;
    }
  }

  &-contentWrapper {
    width: 56%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-#{$start}: 12px;
    padding-#{$end}: 8px;
  }

  &-title {
    margin-bottom: 8px;
    line-height: 36px;

    @include mobile {
      padding-#{$end}: 70px;
    }
  }

  &-description {
    line-height: 25px;
  }

  &-actionPriceWrapper {
    width: 34%;
    padding: 18px 24px;
    border-#{$start}: 1px solid $primary-dark;

    @include mobile {
      flex-basis: 100%;
      border-#{$start}-width: 0px;
      border-top: 1px solid $primary-dark;
    }

    .CvoStepper {
      margin: auto;
    }

    #{$el}-priceWrapper {
      text-align: center;

      .price {
        @include text-xl-figma;
        color: $gray-darker;
        font-weight: 500;
      }

      .recurrency {
        @include text-m-figma;
        color: $gray-darker;
        margin-#{$start}: 8px;
      }
    }

    #{$el}-actionWrapper {
      .member {
        @include text-s-figma;
        color: $gray;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }

  &-action {
    @include button-outline;
    @include button-icon-before('plus', $primary-darker, $white, $white);

    margin-top: 12px;
    justify-content: center;
    width: 100%;

    &:disabled {
      color: $gray-light;
      border-color: $gray-light;
    }

    &:hover,
    &:active,
    &:focus {
      &::before {
        background-color: $white;
      }
    }
  }

  &.is-selected {
    border-color: $success;

    &::before {
      @include selected-check-position;
      background-color: $success;
    }

    &::after {
      @include selected-check-position;
      background-color: $white;
      mask: url($icons-sprite + '#check') no-repeat center;
    }

    h1 {
      color: $success;
    }

    button#{$el}-action {
      &::before {
        mask: url($icons-sprite + '#check') no-repeat center;
        transform: scale(1.75);
      }
    }

    #{$el}-imageWrapper {
      img {
        filter: $success-icon
      }
    }
  }
}
