@font-face {
  font-family: '29LT Bukra';
  src: url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Medium.woff2') format('woff2'),
      url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29LT Bukra';
  src: url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Bold.woff2') format('woff2'),
      url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29LT Bukra';
  src: url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Black.woff2') format('woff2'),
      url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29LT Bukra';
  src: url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Light.woff2') format('woff2'),
      url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29LT Bukra';
  src: url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra.woff2') format('woff2'),
      url($s3-base-url + '/fonts/29LT-Bukra/29LTBukra.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
