.CvoAccordion {
  & .vsa-list {
    --vsa-max-width: 100%;
    --vsa-border: none;
  }

  $item: '.vsa-item';
  & #{$item},
  & #{$item}--is-active {
    & #{$item}__heading {
      border-bottom: 1px solid $gray-light;
      
      span {
        @include h4-figma;
        color: $primary-dark; 
      }
    }
    
    & #{$item}__content {
      span,
      a {
        @include text-s-figma;
      }
    }
  }

  & #{$item} {
    & #{$item}__trigger {
      &__icon--is-default {
        background: none;
        background-color: $primary-dark;
        mask-image: url($icons-sprite + '#chevron-down');
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 120%;
      }

      &__icon {
        margin-left: unset; // reset style so i won't affect rtl
        margin-#{$start}: auto;
      }
    }

    & #{$item}__trigger[aria-expanded=true] {
      & #{$item}__trigger__icon--is-default {
        background: none;
        background-color: $primary-dark;
        mask-image: url($icons-sprite + '#chevron-up');
      }
    }
  }

  
}