.Navigation, .CvoNavigation {
  &-nav {
    display: flex;
    justify-content: space-between;
  
    &.is-expanded {
      display: flex !important;
      position: absolute;
      flex-direction: column;
      background: #fff;
      padding: 10px;
      left: 0;
      right: 0;
      z-index: 5;
  
      .Navigation-item, .CvoNavigation-item {
        padding: 20px;
        white-space: nowrap;
      }
    }
  }
  
  &-hamburgerWrapper {
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  &-hamburger {
    cursor: pointer;
    margin-right: 10px;
    z-index: 1;
    border: none;
    background: transparent;
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &--vertical {
    nav {
      flex-flow: column;
    }
  }
}