.Badge {
  border-radius: 100px;
  border-color: $primary-lighter;
  background-color: $primary-lighter;
  color: $primary-darker;
  font-weight: 500;

  i.subs-icons::before {
    background-color: $gray-darker;
  }

  &--secondary {
    border-color: $secondary;
    background-color: $secondary;

    i.subs-icons::before {
      background-color: $secondary;
    }
  }

  &--lg {
    padding: 16px 20px;
    font-size: 20px;
    line-height: 30px;
  }

  &--md {
    padding: 12px 16px;
    font-size: 16px;
    letter-spacing: -0.48px;
  }

  &--sm {
    padding: 8px 12px;
    font-size: 14px;
    letter-spacing: -0.42px;
  }

  &--xs {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 22px;
  }
}

.promotionalTag {
  @extend .Badge, .Badge--xs;
  gap: 0;
}