.Overlay {
  display: none;
  height: 100vh;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 5;

  &.is-visible {
    display: block;
  }
}

.OverlaySiblingIndex {
  z-index: 10;
}

.OverlaySiblingPosition {
  position: relative;
}