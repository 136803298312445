$el: '.ToggleCheckbox';

.ToggleCheckbox {
  > label {
    cursor: pointer;
    display: block;
    > div {
      position: relative;
      display: inline-block;
      min-width: 48px;
      min-height: 24px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &-track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    transition: background-color .3s;

    &--on {
      #{$el}-knob {
        transform: translateX(24px);
      }
    }
  }

  &-knob {
    position: absolute;
    left: 2px;
    bottom: 2px;
    background-color: #FFFFFF;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
    transition: transform .3s;
  }
}