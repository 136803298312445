.CvoDefinitionList {
  &--cardDetails {
    .CvoDefinitionList {
      &-term {
        color: $gray-darker;
      }
      &-item:not(:last-child) {
        margin-bottom: 16px;
        @include mobile {
          margin-bottom: 24px;
        }
      }
      @include mobile {
        &-item {
          flex-flow: column;
          gap: 8px;
        }
        &-definitionGroup {
          text-align: unset;
        }
      }
    }
  }
}
