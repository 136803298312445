.CvoStepper {
  width: fit-content;

  &-wrapper {
    width: fit-content;

    button {
      @include button-outline;

      width: 24px;
      height: 24px;
      border-radius: 12px;
      padding: 0px;
      display: inline-block;
      line-height: 22px;

      &:disabled
      &[disabled],
      &[disabled="disabled"],
      &.disabled {
        background-color: $white;
        border-color: $gray-light;
        color: $gray-light
      }
    }

    input {
      display: inline-block;
      width: 45px;
      height: 40px;
      margin: 0px 8px;
      border-width: 0 0 1px 0;
      border-color: $gray-light;
      text-align: center;
      @include text-m-figma;
    }
  }
}
