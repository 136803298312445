.Landing {
  h1, h2 {
    color: $primary-darker;
  }

  h1 {
    @include h2-figma;
    font-size: 23px;
    color: $primary-darker;
  }

  .Plans {
    background: $primary-gradient;
    @include mobile {
      padding: 0 20px;
    }

    @include tablet {
      padding: 0 32px;
    }

    @include desktop {
      padding: 0 32px;
    }

    &-inner {
      > h1 {
        color: $white;
      }

      > p {
        color: white;
        margin: 0 auto;
        max-width: 50%;

        @include mobile {
          max-width: 100%;
        }
      }
    }
  }

  .Faq {
    > h1 {
      @include mobile {
        text-align: center;
      }
      @include desktop {
        padding: 0 16px;
      }
    }
    > p {
      @include desktop {
        padding: 0 16px;
      }
    }
  }

  &-steps {
    background-color: $gray-lightest-alt;
    @include mobile {
      padding: 0 20px;
    }

    @include tablet {
      padding: 0 32px;
    }

    .CvoTiles-tileText {
      @include text-s-figma;
      line-height: 24px;
    }
  }

  &-testimonials {
    border: 14px solid $primary-darker;
    padding: 80px 0;
    margin-bottom: 52px;

    h2 {
      @include text-s-figma;
      text-align: center;
      font-weight: 500;
      color: $primary-darker;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    h3 {
      @include h3-figma;
      text-align: center;
      color: $primary-darker;
      margin-bottom: 56px;
    }

    .Reviews-wrapper {
      margin: auto;
      width: fit-content;
      display: flex;
      gap: 56px;

      @include mobile {
        flex-flow: column;
      }
    }
  }
}
