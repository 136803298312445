.Hero {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  display: flex;

  &-videoContainer {
    height: 100%;
    position: absolute;
    width: 100%;

    .CvoVideo {
      height: 200%;
      #{$start}: -40%; // move video slightly to the end
      position: absolute;
      top: -50%;
      width: 200%;

      @include mobile {
        height: 100%;
        #{$start}: -50%;
        top: 0;
      }

      video {
        bottom: 0;
        height: auto;
        left: 0;
        margin: auto;
        max-width: none;
        min-height: 50%;
        min-width: 50%;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;

        @include mobile {
          min-height: auto;
          max-width: 100%;
          top: auto;
        }
      }
    }
  }

  &-content {
    $deg: if($direction == 'ltr', 90deg, 270deg); // set degrees based on direction

    background: linear-gradient($deg, #FFF 23.40%, rgba(5, 21, 131, 0.50) 61.46%, rgba(0, 160, 223, 0.50) 100%);
    display: flex;
    position: relative;
    width: 100%;
    z-index: 1;

    @include mobile {
      background: linear-gradient(180deg, #FFF 49.79%, rgba(5, 21, 131, 0.50) 72.98%, rgba(0, 160, 223, 0.50) 100%);
    }
  }

  &-contentInner {
    @extend .PageContent;

    margin: auto;

    @include mobile {
      padding: 0 20px;
      margin-top: 0;
    }

    @include tablet {
      padding: 0 32px;
    }

  }

  &-contentWrapper {
    width: 40%;

    @include tablet-down {
      width: 50%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &-logo {
    margin-bottom: 20px;

    img {
      width: 190px;
      @include mobile {
        width: 114px;
        margin-top: 17%;
      }
    }
  }

  &-description {
    @include text-l-figma;

    @include mobile {
      @include text-s-figma;
      line-height: 20px;
      font-weight: 500;
    }

    font-weight: 500;
    margin-bottom: 20px;
  }

  &-subtitle {
    @include h3-figma;

    @include mobile {
      @include text-l-figma;
      line-height: 30px;
      font-weight: 500;
    }

    margin-bottom: 20px;
  }

  &-slogan {
    @include text-l-figma;

    color: $primary-darker;
    font-weight: 500;
    margin-bottom: 25px;

    @include mobile {
      @include text-m-figma;
      font-weight: 500;
      color: $primary-darker;

      margin-bottom: 72px;
    }
  }

  &-action {
    padding-left: 70px;
    padding-right: 70px;
  }
}
