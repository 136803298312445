.CancelPlan {
  &-step {
    text-align: center;
    width: 100%;
    min-width: 100%;
    padding-left: 10px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 1000ms ease-in-out;
    transition-delay: -300ms;

    &.is-selected {
      max-height: 1000px;
    }
  }

  .CarouselSlider {
    &-carousel {
      background: none;
    }
  }
}