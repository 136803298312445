.FilterBar {
  &-form form {
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
    }
  }

  .InputField {
    input {
      display: block;
    }
  }
}