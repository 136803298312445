.CvoSelectField {
  @include form-element;

  .select,
  .select:not(.is-multiple):not(.is-loading) {
    &::after {
      border-color: $gray-darker;
      right: unset; // reset default
      #{$end}: 1.125em;
    }

    select {
      padding-right: 12px; // reset default;
      padding-#{$end}: 2.5em;
    }
  }

  .label {
    pointer-events: none; /* let the click pass trough so select opens */
  }
}