.SimpleCart {
  margin-bottom: 12px;

  @include mobile {
    display: none;
  }

  &-couponSuccess, &-couponError, &-couponInfo {
    display: flex;
    align-items: flex-start;
    @include text-xs-figma;
  }

  &-couponSuccess {
    color: $success;
    @extend .j9-icons, .check;
  }

  &-couponError {
    color: $danger;
  }

  &-couponInfo {
    @extend .j9-icons, .information;
  }

  &-section {
    background-color: transparent;
    margin-bottom: 0;
    padding: unset;
    padding-top: 16px;

    &:not(.SimpleCart-couponSection) {
      padding: 16px;
      border-left: 1px solid $primary-dark;
      border-right: 1px solid $primary-dark;
      background-color: $white;
    }

    &:first-child {
      border-top: 1px solid $primary-dark;
      border-left: 1px solid $primary-dark;
      border-right: 1px solid $primary-dark;
      border-bottom: 1px solid $gray-light;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &.SimpleCart-totalSection {
      border-bottom: 1px solid $primary-dark;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }


    .SimpleCart-couponText {
      position: absolute;
      padding-left: 10px;
      z-index: 1;
      color: $gray;
      @include text-xxs-figma;
    }

    .SimpleCart-couponForm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      @include tablet {
        flex-wrap: wrap;

        .SimpleCart-couponError,
        .SimpleCart-couponSuccess,
        .SimpleCart-couponInfo {
          order: 3;
        }
      }
    }

    .SimpleCart-couponInput {
      height: 60px;
      padding-top: 30px;
      text-transform: uppercase;
      width: auto;
      @include text-s-figma;
      @include mobile {
        width: 100%;
      }
    }

    .SimpleCart-couponAction {
      @include button-sm;
    }
  }

  &-item:first-child, &-itemName {
    padding-bottom: 5px;
  }

  &-item.cart-coupon-item {
    font-weight: 500;

    .SimpleCart-itemDescription {
      color: $white;
      background-color: $primary-highlight;
      border-radius: 4px;
      padding: 8px;
    }
    .SimpleCart-itemAmount {
      color: $primary-highlight;
    }
  }

  &-title {
    font-weight: 500;
  }

  &-editButton {
    display: none;
  }

  &-itemName,
  &-itemDescription,
  &-itemAmount {
    color: $gray-darker;
  }

  &-totalText {
    font-weight: normal;
  }

  &-totalAmount {
    font-weight: 500;
  }

  &-totalCurrency {
    font-weight: 500;
  }
}
