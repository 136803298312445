.CvoInformationCard-ListBeneficiaries {
  ul.beneficiaries li {
    min-height: 56px;
    border-bottom: 1px solid $gray-light;
    border-top: unset;

    &.is-selected {
      border: 2px solid $primary-lighter;

      .heading {
        background-color: $primary-lighter;

        span.name {
          @include text-m-figma;
          font-weight: 500;
          color: $primary-dark;
          line-height: 56px;
        }

        &::after {
          mask-image: url($icons-sprite + '#chevron-up');
        }
      }
    }

    .heading {
      align-items: center;
      padding-left: 8px;
      position: relative;

      &::after {
        content: '';
        mask-image: url($icons-sprite + '#chevron-down');
        background-color: $gray-darker;
        height: 24px;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: cover;
        min-width: 12px;
        margin-#{$end}: 14px;
        pointer-events: none;
        position: absolute;
        #{$end}: 0;
        top: 50%;
        margin-top: -12px;
        width: 24px;
      }

      span {
        line-height: 56px;
      }
    }

    &:first-child:not(.is-selected) {
      border-top: 1px solid $gray-light;
    }

    &:first-child.is-selected {
      border-top: none;
    }

    .BeneficiaryDetail {
      padding-top: unset;

      section {
        margin-bottom: unset;

        dt {
          @include text-xxs-figma;
          font-weight: 400;
        }

        dd {
          @include text-s-figma;
          border-bottom: none;
          padding: 4px 0px;
        }
      }
    }
  }

  .CvoInformationCard {
    &-informationWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-action {
      @include add-button;
    }

    &-actionMobile {
      @include add-button;
    }

    &-content {
      order: 1;
      flex-basis: 100%;
    }
  }
}

.BeneficiaryDetail {
  background-color: white;
  max-width: 867px;
  margin: auto;
  padding-top: 24px;

  &-header {
    padding: 0px 20px;

    h1 {
      margin-bottom: 18px;
    }
  }

  .CvoFormBuilder--new {
    padding: 0px;
    margin-top: 32px;

    fieldset {
      margin-bottom: 0px;

      section {
        .CvoFormBuilder-group {

          &:nth-child(3) {
            padding-bottom: 24px;
          }
          padding: 0px 20px;

          select, input {
            border-width: 1px;
          }
        }

        .CvoFormBuilder-actions {
          background-color: $primary-lighter;
          padding: 48px;
          flex-flow: column;
          align-items: center;

          button {
            width: 240px;

            &:last-of-type {
              @include button-outline;
            }
          }
        }

        .CvoRadio {
          &-controlsContainer {
            @include mobile {
              justify-content: unset;
            }
          }

          input {
            border-radius: 11px;
            border: 1px solid $primary-darker;

            &:checked {
              border-width: 6px;
            }
          }
        }
      }
    }
  }
}

.BeneficiariesModal--cancelEdit {
  .vm--modal {
    max-width: 100%;
    @media screen and (max-width: 600px) {
      left: 0 !important;
    }
  }
  .CvoModal-title h1 {
    i {
      @include j9-icon('information');
      filter: brightness(0%) saturate(100%) invert(85%) sepia(16%) saturate(5482%) hue-rotate(359deg) brightness(100%) contrast(108%);
    }
  }
  button.secondary {
    @include button-outline;
  }
}
