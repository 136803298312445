.CvoFormBuilder {
  background-color: $white;
  padding: 20px;

  &-fieldset {
    border: none;
    margin-bottom: 32px;

    &[disabled] {
      button {
        @include button-disabled;
      }
    }
  }

  &-group>div>.label {
    top: 20px;
  }

  &-title {
    @include text-l-figma;

    color: $primary-dark;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &-description,
  &-description p {
    color: $gray;
  }

  &-description p {
    margin-bottom: 12px;
  }

  .CvoDateField,
  .CvoRadio {
    >.label {
      color: $gray;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .CvoDateField-selectContainer {
    @include mobile {
      flex-flow: column;
      gap: 20px;

      > div {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }

  .CvoRadio {
    display: flex;
    flex-direction: column;

    &-controlsContainer {
      display: flex;
      flex: 1;

      @include mobile {
        justify-content: space-between;
      }
    }

    .control {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-#{$end}: 12px;
      }
    }
  }

  .Notice {
    margin-bottom: 24px;
  }

  .help.is-danger {
    color: $danger;
    border-color: $danger;
  }
}

.CvoFormBuilder {
  &--paymentMethods {
    .CvoFormBuilder {
      padding-bottom: 0px;
      label:first-of-type:after {
        content: '';
        background-image: url(#{$s3-base-url}/images/credit-card.svg);
        display: inline-block;
        height: 23px;
        background-repeat: no-repeat;
        width: 76px;
        scale: 1.5;
        margin-#{$start}: 30px;
        @include mobile {
          height: 16px;
          width: 65px;
          margin-#{$start}: 8px;
          display: block;
          margin-top: 8px;
        }
      }
    }
    fieldset {
      margin-bottom: 0px;
    }
  }
}

[dir="rtl"] {
  .CvoFormBuilder {
    &--paymentMethods {
      .CvoFormBuilder {
        label:first-of-type:after {
          @include mobile {
            margin-#{$start}: -8px;
          }
        }
      }
    }
  }
}
