@mixin heading-base {
  color: $primary-dark;
  font-family: $bukra;
  line-height: 30px;
}

@mixin h1-figma($weight: 500) {
  @include heading-base;

  font-size: 40px;
  font-weight: $weight;
  letter-spacing: -1.2px;
}

@mixin h2-figma($weight: 500) {
  @include heading-base;

  font-size: 30px;
  font-weight: $weight;
  letter-spacing: -0.9px;
}

@mixin h3-figma($weight: 500) {
  @include heading-base;

  font-size: 24px;
  font-weight: $weight;
  letter-spacing: -0.72px;
}

@mixin h4-figma($weight: 500) {
  @include heading-base;

  font-weight: $weight;
  font-size: 16px;
  letter-spacing: -0.48px;
}

@mixin h5-figma($weight: 700) {
  @include heading-base;

  font-weight: $weight;
  font-size: 18px;
  letter-spacing: -0.54px;
}

h1 {
  @include h3-figma;
}

h2 {
  @include h4-figma;
}