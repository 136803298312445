.Header {
  background-color: $white;
  border-bottom: 1px solid $gray-light;
  margin-bottom: 40px;
  @include mobile {
    padding: 7px 20px;
  }

  @include tablet {
    padding: 7px 32px;
  }

  &-container {
    @extend .PageContent;
    margin: auto;

    @include tablet-down {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.Header--landing {
  margin-bottom: 0;
  position: fixed;
  transition: $transitionAll;
  width: 100%;
  z-index: 2;
}

.Header--transparent {
  background-color: transparent;
  border-color: transparent;

  .SubscribeHeader {
    transition: $transitionAll;
    opacity: 0;
    visibility: hidden;
  }
}

.Header--registerPrivate {
  .CvoHeader {
    &-loginAccountWrapper {
      display: none;
    }
    &-logoutAccountWrapper {
      order: 1;
    }
    &-forcedLogoutButton {
      border: 1px solid $gray-light;
      &:hover {
        border: 1px solid $gray-light;
      }
    }
  }
}

.CvoHeader {
  &-logoContainer {
    transition: $transitionAll;

    @include mobile {
      order: 1;
    }

    img {
      width: 130px;

      @include mobile {
        width: 100px;
      }
    }
  }

  &-actions {
    position: unset;

    @include mobile {
      order: 2;
    }

    > button,
    > div:not(:last-child) {
      margin-#{$end}: 29px;
    }
  }

  &-languageContainer {
    @include mobile {
      display: none;
    }
  }

  &-loginAccountWrapper {
    position: relative;

    @include mobile {
      display: none;
    }
  }

  $icon-size: 30px;

  &-loginButton,
  &-userButton,
  &-forcedLogoutButton {

    @include button-outline-sm;

    border-color: $gray-light;
    color: $primary-dark;
    padding: 11px;
    padding-#{$start}: calc(40px + #{$icon-size});
    padding-#{$end}: 40px;
    position: relative;
    transition: $transitionAll;

    &::before {
      padding: 1px;
      border: 1px solid $gray-light;
      background-color: $primary-dark;
      bottom: 0;
      content: '';
      display: block;
      mask:
        linear-gradient(#fff 0 0) #{$end} /1px 100%,
        url($icons-sprite + '#account');
      mask-position: center #{$end};
      mask-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: $icon-size;
      #{$start}: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      background-color: $white;
      border-color: $gray-light;
      color: $primary-dark;
    }
  }

  &-userButton {
    padding-#{$end}: calc(20px + #{$icon-size});
    padding-#{$start}: calc(20px + #{$icon-size});

    &::after {
      content: '';
      background-color: $primary-dark;
      bottom: 0;
      content: '';
      display: inline-block;
      mask-image: url($icons-sprite + '#chevron-down');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 150%;
      position: absolute;
      top: 0;
      transition: $transitionAll;
      width: $icon-size;
      #{$end}: 5px;
    }

    &.CvoOverlaySiblingPosition::after { // active state
        mask-image: url($icons-sprite + '#chevron-up')
    }
  }

  &-userPanel {
    background-color: $white;
    border: 1px solid $gray-light;
    margin-top: 10px;
    right: unset;
    #{$end}: 0;

    hr {
      display: none;
    }
  }

  // hide unused elements
  &-fullName,
  &-userEmail,
  &-userLinks {
    display: none;
  }

  &-userAreaButton,
  &-logoutButton {
    background: none;
    border: none;
    color: $primary-dark;
    display: block;

    &:hover,
    &:active,
    &:focus {
      background: none;
      border: none;
      color: $primary-dark;
    }
  }

  .CvoSlideMenu {
    order: 3;
  }

  .SubscribeHeader {
    @include button-secondary-sm;

    padding-left: 40px;
    padding-right: 40px;

    @include mobile {
      @include button-secondary;
      @include text-xs-figma;

      font-weight: 500;
      margin: auto;
      padding-left: 31px;
      padding-right: 31px;
      position: absolute;
      #{$end}: 20px;
    }
  }
}

.CvoHeader--transparent {
  .CvoHeader {
    &-logoContainer {
      opacity: 0;
    }

    &-loginButton {
      background-color: transparent;
      color: $white;

      &::before {
        background-color: $white;
      }
    }

    &-userButton {
      background-color: transparent;
      color: $white;

      &::before,
      &::after {
        background-color: $white;
      }
    }
  }

  .CvoLanguageSelector {
    &-panelButton {
      background-color: transparent;

      &::after {
        background-color: $white;
      }

      &:hover,
      &:active,
      &:focus,
      &.is-active {
        border-color: $gray-light;
        background-color: transparent;
      }

      span {
        color: $white;
      }
    }

    &-currentLanguage {
      border-#{$end}: 1px solid $white;
    }
  }
}

.CvoHeader--landing:not(.CvoHeader--transparent) {
  .CvoSlideMenu {
    display: none;
  }
}
