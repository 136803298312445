.Tiles {
  &-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-imageContainer {
    margin-bottom: 20px;
  }

  &-tile {
    display: flex;
    flex: 1 0 21%;
    margin: 20px 10px;

    @include tablet-down{
      flex: 1 0 45%;
    }

    @include mobile {
      flex: 1 0 100%;
    }

    &--top,
    &--bottom {
      flex-direction: column;
    }

    & > span {
      flex: 1;
    }
  }

  &-imageContainer {
    display: flex;
  }

  &-tileImage {
    margin: auto;
  }

  &-textContainer {
    display: flex;
  }

  &-tileText {
    margin: auto;
    text-align: center;
    width: 176px;
  }
}