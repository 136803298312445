.PrivatePage {
  &-mainContent {
    .Card-toolbar > h3, h1 {
      @include h3-figma;
    }

    .Card-toolbar {
      align-items: baseline;
    }

    .List-label {
      color: $gray-darker
    }

    .Modal {
      text-align: center;

      &-container {
        max-width: 480px;
      }
      &-title {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      &-titleIcon {
        display: block;

        &::before {
          width: 35px;
          height: 35px;
          background-color: $warning;
          mask-size: cover;
        }
        margin-bottom: unset;
      }
      &-actions {
        justify-content: center;
        margin-top: 48px;
        
        button {
          width: 140px;
        }
      }
    }
    
    .BeneficiariesView {
      .SubscriptionDetailsCard,
      .CvoInformationCard {
        max-width: 867px;
        margin-bottom: 24px;
        margin: auto;
        background-color: $white;
      }

      .Card-actions {
        button {
          @include button-transparent;
          display: flex;
          font-weight: 400;

          &::before {
            content: '';
            display: block;
            background-color: $primary-dark;
            mask: url($icons-sprite + '#pencil') no-repeat center;
            width: 22px;
            height: 15px;
            transform: scale(1.9);
            mask-position: center;
          }
        }
      }

      .CvoInformationCard {
        &-content {
          @include text-m-figma;
          h2 {
            @include text-l-figma;
            font-weight: 500;
          }
        }
        &-extrasWrapper {
          border: 1px solid $gray-lighter;
          padding: 16px 24px;
          h3 {
            @include text-l-figma;
            font-weight: 500;
            color: $primary-light;
            margin-bottom: 24px;
            i::before {
              background-color: $primary-light;
              scale: 1.5;
              transform: translateY(2px);
              display: inline-block;
            }
          }
        }
        li.CvoDefinitionList-item {
          @include mobile {
            flex-flow: column;
            p.CvoDefinitionList-definition {
              word-wrap: break-word;
              text-align: #{$start};
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  &-sectionTitle {
    text-align: center;
    margin-bottom: 48px;
  }
}
