@function getIconUrl($id) {
  @return url($icons-sprite + '#' + $id);
}

@mixin icon($id) {
  &.#{$id} {
    &:before {
      mask-image: getIconUrl($id);
    }
  }
}

.j9-icons {
  &::before {
    background-color: $gray-darker;
    content: '';
    display: block;
    height: 25px;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 25px;
  }

  &.secondary::before {
    filter: $secondary-icon;
  }

  @include icon('account');
  @include icon('airplane');
  @include icon('add');
  @include icon('add-on');
  @include icon('airplane');
  @include icon('bag-drop');
  @include icon('bag-drop-LTR');
  @include icon('calendar');
  @include icon('cancel');
  @include icon('change');
  @include icon('check');
  @include icon('check-in');
  @include icon('check-in-LTR');
  @include icon('close');
  @include icon('credit-card');
  @include icon('discount');
  @include icon('extra-luggage');
  @include icon('information');
  @include icon('lounge-access');
  @include icon('menu');
  @include icon('seat-selection');
  @include icon('seat-selection-LTR');
  @include icon('select');
  @include icon('star');
  @include icon('tag');

  &.color-success {
    &::before {
      background-color: $success;
    }
  }

  &.color-danger {
    &::before {
      background-color: $danger;
    }
  }
}

// FIX to avoid bulma setting an arrow to the "select" icon
.j9-icons.select::after {
  content: none !important;
}

@mixin j9-icon($id) {
  display: inline-block;
  &::before {
    background-color: $gray-darker;
    content: '';
    display: block;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: getIconUrl($id);
  }
}

@mixin subs-icon($id, $color: $gray-darker) {
  @include j9-icon($id);

  &::before {
    background-color: $gray-darker;
  }
}