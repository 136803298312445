$namespace: "Datepicker";
@import "vue2-datepicker/scss/index.scss";

.Datepicker {
  &-datepicker {
    width: 100%;
  }

  &-input {
    height: 2.25em;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    padding-right: 30px;
    font-size: 1rem;
    line-height: 1.5;
    box-shadow: none;
    color: #363637;
  }

  &-icon-calendar,
  &-icon-clear {
    color: #000;
  }

  &-btn {
    color: #000;
  }

  &-datepicker-main {
    color: #000;
  }

  &-datepicker-popup {
    box-shadow: 0 2px 2px rgb(0 0 0 / 16%);
  }

  &-calendar-content .cell:hover {
    color: #000;
    background-color: #f3f9fe;
  }

  &-calendar-content .cell.active {
    color: #fff;
    background-color: #000;
  }
}

[dir="rtl"] {
  .Datepicker {
    &-icon-calendar,
    &-icon-clear {
      left: 8px;
      right: unset;
    }

    // Popup buttons
    &-btn-icon-double-left {
      float: right;

      i {
        &::before,
        &::after {
          transform: rotate(135deg) scale(0.7);
        }
      }
    }

    &-btn-icon-left {
      float: right;

      i {
        &::before {
          transform: rotate(135deg) scale(0.7);
        }
      }
    }

    &-btn-icon-double-right {
      float: left;

      i {
        &::before,
        &::after {
          transform: rotate(-45deg) scale(0.7);
        }
      }
    }

    &-btn-icon-right {
      float: left;

      i {
        &::before {
          transform: rotate(-45deg) scale(0.7);
        }
      }
    }
  }
}
