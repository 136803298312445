.Notice {
  border-radius: $border-radius;
  border: 2px solid $gray-darker;
  display: flex;
  padding: 16px;

  &::before {
    background-color: $gray-darker;
    border-radius: 6px;
    content: '';
    display: block;
    margin-top: -4px;
    margin-bottom: -4px;
    margin-#{$end}: 10px;
    margin-#{$start}: -10px;
    width: 8px;
  }

  & &-icon {
    @include blink;

    background-color: $gray-darker;
    margin-#{$end}: 10px;
    mask-image: url($icons-sprite + '#alert');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 150%;
    height: 24px;
    width: 24px;
  }

  & &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  & &-title {
    @include h4-figma;
    
    margin-bottom: 12px;
  }

  & &-text {
    color: $gray-darker;
    margin-bottom: 0;
  }

  & &-action {
    margin-#{$start}: auto;

    @include button-wide;
  }
  
  // variants
  &--warning {
    border-color: $warning;
    
    &::before {
      background-color: $warning;  
    }
  }

  // variants
  &--info {
    border-color: $primary-dark;

    &::before {
      background-color: $primary-dark;  
    }

    .Notice {
      &-icon {
        display: none;
      }
    }
  }
}

