.LanguageSelector {
  position: relative;

  &-panel {
    position: absolute;
    right: 0;
    display: none;

    &.is-visible {
      display: block;
    }
  }

  &-closePanelButton {
    display: none;
    font-family: monospace;
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
      display: block;
    }
  }
}
