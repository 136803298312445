.SubscriberDetails {
  &-content {
    display: flex;
    gap: $container-padding-md;

    @include mobile {
      gap: $container-padding-sm;
      flex-direction: column;
    }
  }

  &-column {
    flex: 1;
  }

  &-detailList {
    li {
      strong {
        @include mobile {
          display: block;
        }
      }
    }
  }
}