.CvoDropdownMenu {
  &-wrapper {
    position: relative
  }

  &-listWrapper {
    border: 1px solid $gray-light;
    min-width: 150px;
    position: absolute;
    #{$end}: 0;
  }

  &-button {
    padding: 0 !important; // override bulma

    span {
      align-items: center;
      display: flex;
      color: $primary-dark;
      font-weight: 500;

      i::before {
        background-color: $primary-dark;
      }
    }
  }

  &-item {
    @include text-s-figma;

    align-items: center;
    color: $gray-darker;
    display: flex;
    padding: 8px !important; // override bulma
    background-color: $white;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-light;
    }

    i::before {
      background-color: $gray-darker;
      mask-size: 130%;
    }
  }
}