.MastercardEditView {
  &-header {
    @extend .CvoInformationCard;
    border-bottom: none;
    padding-bottom: 26px;
    max-width: 100%;

    h1 {
      margin-bottom: 16px;
    }
  }
}