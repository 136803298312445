.List-item {
  strong {
    @include mobile {
      display: block;
    }
  }

  &--spaced {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }
  }
}