.Modal {
  @include overlay;

  display: flex;

  &.is-loading.default-loader {
    position: absolute;
  }

  &-container {
    background-color: $white;
    box-shadow: $box-shadow;
    margin: auto;
    padding: $container-padding-md;
    position: relative;
  }

  &-content {
    padding: $container-padding-md;
    box-sizing: border-box;

    @include mobile {
      width: 100%;
    }
  }

  &-title {
    padding: $container-padding-md;
  }

  &-closeButton {
    display: flex;
    top: $container-padding-sm;
    height: $button-size;
    padding: 0;
    position: absolute;
    width: $button-size;
    #{$end}: $container-padding-sm;

    .ActionButton-iconBefore {
      margin: auto;
    }
  }

  &-titleIcon {
    margin-bottom: 15px;
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &::before {
      height: 30px;
      margin: auto;
      width: 30px;
    }
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

[dir="rtl"] {
  .Modal {
    &-title {
      position: relative;
      padding: $container-padding-md;
    }
    .Close {
      left: 20px;
      right: unset;
    }
  }
}
