$button-size: 24px;
$box-shadow: 0 0 5px 0px #000000 !default;
$container-padding-lg: 38px !default;
$container-padding-md: 24px !default;
$container-padding-sm: 12px !default;
$icon-size: 24px !default;
$transition-duration: 100ms !default;

$transition-duration-sm: 100ms !default;
$transition-duration: 250ms !default;
$transition-duration-lg: 500ms !default;
$transition-duration-xl: 1s !default;

$border-radius-xl: 16px !default;
$border-radius-lg: 12px !default;
$border-radius: 10px !default;
$border-radius-sm: 8px !default;
$border-radius-xs: 4px !default;

$white: #FFFFFF !default;
$gray-lighter: #E1E1E1 !default;
$gray-light: #B3B3B3 !default;
$gray: #8A8A8A !default;
$gray-dark: #626262 !default;
$gray-darker: #333333 !default;

$primary: #004295 !default;
$primary-light: #D0E2F9 !default;

$secondary: #F85900 !default;
$secondary-light: #FFBE9A !default;

$success: #4CAF50 !default;
$success-light: #C9E7CB !default;

$warning: #FF8A00 !default;
$warning-light: #FFEAB3 !default;

$danger: #FF1100 !default;
$danger-light: #FFB8B3 !default;

$disabled: $gray !default;
$disabled-light: $gray-lighter !default;

$start: left !default;
$end: right !default;

$icons-sprite: $s3-base-url + '/images/sprite.css.svg' !default;
