$el: '.Badge';
.Badge {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    border: 1px solid;
    border-radius: $border-radius;
    border-color: $primary;
    color: $primary;
  
  i.subs-icons::before {
    background-color: $primary;
  }

  &--secondary {
    border-color: $secondary;
    color: $secondary;

    i.subs-icons::before {
      background-color: $secondary;
    }
  }

  &--active {
    background-color: $primary;
    color: $white;
    
    i.subs-icons::before {
      background-color: $white;
    }
    
    &#{$el}--secondary {
      background-color: $secondary;
      color: $white;

      i.subs-icons::before {
        background-color: $white;
      }
    }
  }

  &--disabled {
    color: $disabled;
    border-color: $disabled;

    i.subs-icons::before {
      background-color: $disabled;
    }
  }

  &--lg {
    padding: 16px;
    font-size: 24px;
  }

  &--md {
    padding: 12px;
    font-size: 20px;
  }

  &--sm {
    padding: 8px;
    font-size: 16px;
  }

  &--xs {
    padding: 4px;
    font-size: 16px;
  }
}