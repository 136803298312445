.CartContainer {
  &-disclaimer {
    display: none;
    color: $gray;
  }

  @include mobile {
    background-color: $primary-dark;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 5;
    
    &-disclaimer {
      display: block;
      color: $gray;
      overflow: hidden;
      padding: 20px 0;
    }
    
    .CvoMiniCart.is-expanded + p {
      padding: 12px 20px;
    }
  }
}