.FieldDisplay {
  &-label {
    font-weight: bold;
  }

  &-boolean {
    display: inline-block;
    border: 1px solid black;
    width: 20px;
    height: 20px;

    &::before {
      font-size: 20px;
      line-height: 1;
    }

    &--true::before {
      content: "\2713";
    }

    &--false::before {
      content: "\2715";
    }
  }
}
