@mixin text-base($family, $weight: 400, $color: $gray-dark) {
  &:lang(ru) {
    font-family: $arial;
  }

  color: $color;
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  line-height: 30px;
}

@mixin text-xl-figma {
  @include text-base($bukra);

  font-size: 24px;
  letter-spacing: -0.72px;
}

@mixin text-l-figma {
  @include text-base($bukra);

  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 30px;
}

@mixin text-m-figma($font-weight: 400, $color: $gray-dark) {
  @include text-base($bukra, $font-weight, $color);

  font-size: 16px;
  letter-spacing: -0.48px;
  line-height: 24px;
}

@mixin text-s-figma {
  @include text-base($bukra);

  font-size: 14px;
  letter-spacing: -0.42px;
  line-height: 20px;
}

@mixin text-xs-figma {
  @include text-base($bukra);

  font-size: 13px;
  letter-spacing: -0.39px;
  line-height: 21px;
}

@mixin text-xxs-figma {
  @include text-base($bukra);

  font-size: 12px;
  letter-spacing: -0.36px;
  line-height: 22px;
}

p,
li,
span,
a {
  @include text-m-figma;
}

// Helpers
.text-center {
  text-align: center
}

.text-l {
  @include text-l-figma;
}

dd {
  line-height: 30px;
}
