.CvoMiniCart {
  display: none;
  padding: 12px 20px;
  position: relative;

  @include mobile {
    display: block;
  }

  &-summary {
    min-height: 58px;
    padding-#{$end}: 155px;
    transform: $transitionAll;
  }

  &-summaryTitle {
    flex: 0;
    order: 2;
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: $transitionAll;
    
    h1 {
      @include text-m-figma;

      color: $white;
      font-weight: 500;
    }
  }
  
  &-summaryTotal {
    flex: 1;
    order: 3;
  }

  &-totalPrice {
    color: $white;
    font-weight: 500;
  }

  &-totalCurrency {
    @include text-s-figma;
    
    color: $white;
  }

  &-expandButton {
    order: 1;
    background-color: transparent;
    padding: 12px;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
      background-image: none;

    }
  }

  &-summaryArrow {
    background-color: $white;
    height: 24px;
    left: unset; // reset default
    #{$start}: -10px;
    mask-image: url($icons-sprite + '#chevron-up');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: cover;
    width: 24px;
    transform: unset;

    &::before,
    &::after {
      content: none;
    }
  }

  &-continueButton {
    align-items: center;
    bottom: 12px;
    display: flex;
    justify-content: center;
    min-width: 0;
    order: 4;
    position: absolute;
    transition: $transitionAll;
    transition-delay: 300ms;
    #{$end}: 20px;
    
    &::after {
      background-color: $primary-darker;
      content: '';
      display: inline-block;
      height: 24px;
      mask-image: url($icons-sprite + '#chevron-' + $end);
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: cover;
      width: 24px;
    }
  }

  &-panel {
    background-color: transparent;
    box-shadow: none;
    display: block;
    max-height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    transition: $transitionAll;
    transition-delay: 150ms;
  }

  &-section {
    border: none;
    padding: 0;

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  &-itemWrapper {
    margin-bottom: 10px;
  }

  &-itemDescription,
  &-itemName,
  &-itemPrice,
  &-itemCurrency {
    color: $white;
  }

  &-itemDescription {
    margin-bottom: 12px;
  }

  &-itemName {
    margin-bottom: 12px;
  }

  &-itemPrice {
    font-weight: 500;
  }

  &.is-expanded {
    $el: '.CvoMiniCart';
    
    .CvoMiniCart {
      &-summary {
        padding-#{$end}: 0;
        transform: $transitionAll;
      }

      &-summaryTitle {
        flex: 1;
        max-width: 100%;
        
        h1 {
          @include text-m-figma;

          color: $white;
          font-weight: 500;
        }
      }

      &-summaryTotal {
        flex: unset;
        order: 3;
        margin-#{$end}: 0;
      }

      &-summaryArrow {
        mask-image: url($icons-sprite + '#chevron-down');
        transform: unset;
      }

      &-continueButton {
        bottom: 16px;
        justify-content: center;
        min-width: calc(100% - 40px);
        text-align: center;
      }

      &-panel {
        margin-bottom: 10px;
        max-height: 1000px;
        opacity: 1;
        padding-top: 20px;
        padding-bottom: calc(12px + 58px);
      }
    }
  }
}