.CvoLanguageSelector {
  &-panelButton {
    $icon-size: 30px;

    @include button-outline-sm;

    border-color: $gray-light;
    display: flex;
    padding: 11px;
    padding-#{$end}: calc(16px + #{$icon-size});
    padding-#{$start}: 16px;
    position: relative;

    &::after {
      content: '';
      background-color: $primary-dark;
      bottom: 0;
      content: '';
      display: inline-block;
      mask-image: url($icons-sprite + '#chevron-down');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 150%;
      position: absolute;
      top: 0;
      transition: $transitionAll;
      width: $icon-size;
      #{$end}: 5px;
    }

    &.is-active::after {
        mask-image: url($icons-sprite + '#chevron-up')
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      background-color: $white;
      border-color: $gray-light;
      color: $primary-darker;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }

  &-currentLanguage {
    color: $primary-dark;
    border-#{$end}: 1px solid $gray;
    margin-#{$end}: 8px;
    padding-#{$end}: 8px;
  }

  &-currentCurrency {
    color: $primary-dark;
    display: inline-block;
  }

  &-panel {
    background-color: $white;
    border: 1px solid $gray-light;
    margin-top: 10px;
    padding: 16px;
    right: unset; // reset default
    width: 300px;
    #{$end}: 0;
  }

  &-panelTitle {
    @include text-xs-figma;

    margin-bottom: 24px;
  }

  &-closePanelButton {
    display: none;
  }

  &-confirmButton {
    @include button-sm;

    width: 100%;
  }

  .CvoSelectField {
    margin-bottom: 24px;

    .select.is-success select {
      border-color: $gray-light;
    }
  }
}

.CvoLanguageSelector--mobile {
  .CvoLanguageSelector {
    &-panelButton {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0;
      padding-#{$start}: 0;
      width: 100%;

      &::after {
        background-color: $white;
      }

      &.is-active {
        color: $primary-dark;
        background-color: $white;
        padding-#{$start}: 16px;

        &::after {
          background-color: $primary-dark;
        }

        .CvoLanguageSelector {
          &-currentLanguage,
          &-currentCurrency {
            color: $primary-dark;
          }

          &-currentLanguage {
            border-color: $primary-dark;
          }
        }
      }

      &:hover,
      &:active,
      &:focus {
        border-color: transparent;
      }
    }

    &-currentLanguage,
    &-currentCurrency {
      color: $white;
      font-size: 16px;
      font-weight: 700;
    }

    &-currentLanguage {
      // border-color: $white;
      border-#{$end}: 3px solid $white;
    }

    &-panel {
      background-color: transparent;
      border: 1px solid $white;
      left: unset;
      margin-top: 0;
      right: unset;
      width: 100%;
    }

    &-panelTitle {
      display: none;
    }

    &-confirmButton {
      @include button-outline;

      background-color: transparent;
      border-color: $gray-light;
      color: $white;

      &:hover,
      &:active,
      &:focus,
      &.is-active {
        border-color: $gray-light;
        background-color: transparent;
        background-image: none;
      }
    }
  }

  .CvoOverlay {
    background: none;
  }

  .CvoSelectField {

    &.is-active {
      label.label {
        color: $white;
        font-weight: normal;
        margin-#{$start}: 0;
      }
    }

    label.label {
      color: $white;
      font-weight: normal;
      margin-#{$start}: 0;
    }



    .select,
    .select:not(.is-multiple):not(.is-loading) {
      &::after {
        border-color: $white;
        right: unset; // reset default
        #{$end}: 1.125em;
      }

      select {
        background-color: transparent;
        border: none;
        color: $white;
        padding-top: 28px;
        padding-#{$start}: 0;
      }
    }
  }
}
