.CvoClose {
  background: none;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    background: none;    

    &::after,
    &::before {
      background-color: $gray-darker;
    }
  }

  &::after,
  &::before {
    background-color: $gray-darker;
  }
}