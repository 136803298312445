.ThreedsUI {
  &-container {
    position: absolute;
    z-index: 10;
    width: calc(100% - 40px);
    left: 0;
    margin: 0 20px;
    text-align: center;
    iframe {
      min-height: 21rem;
      background-color: $white;
    }
  }
  &.overlay {
    @include fade-background(rgba($gray-dark, 0), rgba($gray-dark, 0.5));
    
    width: 100%;
    background-color: rgba($gray-dark, 0.5);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
  }
}

.Payment {
  position: relative;
  @include form-page;

  @include mobile {
    padding-bottom: 82px;
  }

  &-paymentProviderForm {
    background-color: $white;
    padding: 20px;
  }

  &-formContainer {
    margin-bottom: 20px;
    padding: 40px 20px;

    @include tablet {
      padding: 40px;
    }
  }

  &-form {
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    .col-2-tablet {
      display: flex;
      row-gap: 25px;
      flex-direction: column;
      flex-wrap: nowrap;

      @include tablet {
        column-gap: 16px;
        display: grid;
        grid-template-columns: calc(50% - 9px) calc(50% - 9px);
      }

      .validation-text.expiryDate {
        @include mobile {
          bottom: -25px;
        }
      }
    }
  }

  .FormControl {
    position: relative;
    z-index: 1;

    &-inputGroup {
      max-height: 60px;
      background-color: $white;
      box-shadow: 0 0 0 1px $gray-light;

      &.invalid {
        box-shadow: 0 0 0 1px $danger;
      }
    }

    &-expiryDate {
      display: inline-block;
      background-color: transparent;
    }

    &-wFull {
      width: 100%;

      &.invalid {
        border: 1px solid $danger;
      }
    }

    &-monthDate {
      width: calc(50% - 9px);
      border: none;
    }

    &-yearDate {
      width: 50%;
      border: none;
    }

    &-slash {
      bottom: 22px;
      color: $gray-light;
      left: calc(50% - 4px);
      position: absolute;
      width: 1rem;
      height: 1rem;

      @include tablet {
        bottom: 24px;
      }
    }

    &-label {
      @include text-s-figma;
      color: $gray;
      position: absolute;
      top: calc(50% - 10px);
      z-index: 2;
      transition: transform 200ms ease-in, font-size 200ms ease-in;
      margin-#{$start}: 12px;

      &.focus {
        transform: translateX(6.25px * $transform-to-start) translateY(-20px) scale(0.9);
        transition: transform 200ms ease-in-out, font-size 200ms ease-in-out;
      }

      &.invalid {
        color: $danger;
      }
    }
  }

  .validation-text {
    font-size: 0.75rem;
    position: absolute;
    top: 56px;
    left: 0;
    color: $danger;
    width: 100%;
  }

  .Payment-form {
    .validation-text {
      bottom: -20px;
    }
  }

  .Payment-submit {
    display: block;
    margin: 0 auto;
    @include mobile {
      width: 100%;
    }
  }

  .CvoFormBuilder .CvoCheckbox-wrapper {
    input {
      flex-shrink: 0;
      min-width: 10px;
    }

    label {
      flex-shrink: 1;
    }
  }

  // add padding to align with date fields
  .CvoSelectField.nationality {
    margin-top: 24px;
  }
}

[dir="rtl"] {
  .Payment {
    .CvoFormBuilder {
      // custom mq to fix arabic issues
      @media screen and (max-width: 1090px) {
        &-formElement {
          width: 100%;
        }
      }

      @include mobile {
        .CvoDateField-selectContainer {
          > div:first-child {
            margin: 0;
          }

          > div:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
