.TwoColumns {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  &-mainContent {
    width: 70%;
    margin-#{$end}: 36px;

    @include mobile {
      order: 2;
      width: 100%;
    }
  }

  &-sideContent {
    width: 30%;
    
    .CartContainer-disclaimer {
      display: block;
      color: $gray;
    }

    @include mobile {
      .CartContainer-disclaimer {
        display: none;
      }
      width: 100%;
    }
  }
}