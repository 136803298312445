.Card {
  box-shadow: $box-shadow;

  &--disabled {
    pointer-events: none;
  }

  > .Card {
    border: none;
  }

  > hr {
    margin: 0 $container-padding-md;

    @include mobile {
      margin: 0 $container-padding-sm;
    }
  }
}