.Table {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &-title {
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-bottom: 5px;
  }

  &-tableWrapper {
    border: none;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 4px;             /* Chrome, Safari: Sets height of scrollbar */
      background-color: #E1E1E1;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #8A8A8A;         /* Custom color for scrollbar thumb */
      border-radius: 100px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background: #626262;         /* Darker color on hover */
    }
  }

  &-table {
    border-collapse: collapse;
    padding: 5px;
    text-align: left;
    width: 100%;
  }

  &-header {
    font-weight: normal;
    padding: 10px;
  }

  &-headerContent {
    font-size: 1.2rem;
  }

  &-row {
    border-bottom: none;
  }

  &-item {
    padding: 10px;
    border-right: none;
  }

  &-action,
  &-tableData {
    display: inline-block;
    margin-right: 0.5rem;
  }

  &-noResultsRow {
    height: 200px;
  }

  &-noResults {
    position: absolute;
    width: 100%;
    user-select: none;
    pointer-events: none;
    text-align: center;
    color: $gray-light;
    margin-top: -25px;
  }
}

[dir="rtl"] {
  .Table {
    &-table {
      text-align: right;
    }
  }
}
