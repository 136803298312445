.TripDisplay {
  &-segment {
    align-self: center;
    flex: 1;
    display: flex;
    text-align: center;

    @include mobile {
      align-self: unset;
      text-align: unset;
      margin-bottom: 12px;
    }
  }

  &-segmentInner {
    width: 100%;

    @include mobile {
      display: grid;
      grid-template-areas:
        'divider tripNumber'
        'divider tripDuration';
      grid-template-columns: 24px 1fr;
    }
  }

  &-divider {
    display: flex;

    @include mobile {
      flex-direction: column;
      grid-area: divider;
      min-height: 70px;
      order: 1;
    }

    &:before,
    &:after {
      content: '';
      border-top: 1px solid #000;
      display: block;
      flex: 1;
      height: 1px;
      margin: auto 12px;

      @include mobile {
        border-top: unset;
        border-left: 1px solid #000;
      }
    }
  }

  &-number {
    @include mobile {
      align-content: end;
      grid-area: tripNumber;
      order: 2;
    }
  }

  &-duration {
    @include mobile {
      grid-area: tripDuration;
      order: 2;
    }
  }
}