@mixin form-element {
  position: relative;

  &.is-active {
    label.label {
      font-size: 12px;
      transform: none;
    }

    .CvoForm-field {
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &.is-empty {
    .label {
      font-size: 14px;
      transform: translateY(100%);
    }
  }

  .label {
    color: $gray;
    font-size: 12px;
    font-weight: normal;
    margin-#{$start}: 12px;
    position: absolute;
    transition: transform 200ms ease-in-out, font-size 200ms ease-in-out;
    top: 10px;
    z-index: 1;

    &.has-error {
      color: $danger;
    }
  }

  .CvoForm-field {

    &.help.is-danger select,
    &.is-success select,
    &.help.is-danger.input,
    &.is-success.input {
      box-shadow: none;
    }

    &::placeholder {
      transition: opacity 200ms ease-in-out;
      opacity: 0;
    }
  }

  input,
  select {
    @include text-m-figma($font-weight: lighter, $color: $gray-darker);
    border: 1px solid $gray-light;
    border-radius: 0;
    box-shadow: none;
    height: 60px;
    padding: 20px 12px 0px;
  }
}