$gap: 10px;

.FormBuilder {
  &-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gap;
    margin-right: -$gap;

    >div,
    >dl {
      padding: $gap;
    }

    .col-1 {
      width: 1% * calc(100 / 12);
    }

    .col-2 {
      width: 1% * 2 * calc(100 / 12);
    }

    .col-3 {
      width: 1% * 3 * calc(100 / 12);
    }

    .col-4 {
      width: 1% * 4 * calc(100 / 12);
    }

    .col-5 {
      width: 1% * 5 * calc(100 / 12);
    }

    .col-6 {
      width: 1% * 6 * calc(100 / 12);
    }

    .col-7 {
      width: 1% * 7 * calc(100 / 12);
    }

    .col-8 {
      width: 1% * 8 * calc(100 / 12);
    }

    .col-9 {
      width: 1% * 9 * calc(100 / 12);
    }

    .col-10 {
      width: 1% * 10 * calc(100 / 12);
    }

    .col-11 {
      width: 1% * 11 * calc(100 / 12);
    }

    .col-12 {
      width: 1% * 12 * calc(100 / 12);
    }

    @include mobile {

      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        width: 100%;
      }
    }
  }
}