.YourPlan {
  &-content {
    display: flex;
    gap: $container-padding-md;

    @include mobile {
      gap: $container-padding-sm;
      flex-direction: column;
    }
  }

  &-planInfo,
  &-usageInfo {
    flex: 1;
  }
}