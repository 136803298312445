@mixin cancel-plan-slide($el) {
  #{$el} {
    &-title {
      margin-bottom: 16px;
    }

    &-actions {
      margin: 40px auto;
      display: flex;
      justify-content: space-around;

      button {
        padding: 20px 16px;
      }
    }

    &-information, &-terms, &-terms a {
      @include text-s-figma;
      color: $gray-light;
    }
  }
}

.CancelPlan {
  .Modal {
    &-container {
      max-width: 600px;
      padding: 0;
    }

    &-content {
      padding: 0;
    }

    &-title {
      padding: 32px 0;

      &:after {
        display: none;
      }
    }

    &-closeButton {
      @include j9-icon('close');
      background-color: transparent;
      border-width: 0;
      z-index: 1;

      &:hover, &:active, &:focus {
        background-color: transparent;
        background-image: unset;
        border-width: 0;
      }

      &::after {
        height: unset;
        width: unset;
      }

      &::before {
        transform: unset;
        background-color: $gray-dark;
        min-width: 24px;
        min-height: 24px;
        top: 0;
        #{$start}: 12px;
      }
    }
  }

  &-step:not(:last-child) {
    padding: 44px 36px 20px 36px;
  }

  &-step:last-child {
    padding: 16px 36px 12px 36px;
  }

  &-message {
    h1 {
      @include text-xl-figma;
      font-weight: 500;
      color: $primary-dark;
      margin-bottom: 36px;

      &::before {
        width: 35px;
        height: 35px;
        margin-#{$end}: 12px;
        transform: translateY(10px);
      }
    }

    p {
      margin-bottom: 44px;
    }
  }

  &-message--success {
    h1 {
      @include j9-icon('check');
      &::before {
        background-color: $success;
        display: inline-block;
      }
    }
  }

  &-message--failure {
    h1 {
      @include j9-icon('close');
      &::before {
        background-color: $danger;
        display: inline-block;
      }
    }
  }

  .PlanComparison {
    @include cancel-plan-slide('.PlanComparison');

    &-title {
      @include h1-figma;
    }

    &-description {
      @include text-l-figma;
      margin-bottom: 24px;
    }

    &-actions {
      flex-flow: row-reverse;

      button:last-child {
        @include button-outline;
        padding: 20px 16px;
      }
    }

    table {
      width: 100%;
      text-align: left;

      th, td span {
        @include text-s-figma;
        padding: 0;
      }

      td:last-child span {
        @include j9-icon('check');
        display: flex;

        &::before {
          width: 20px;
          height: 20px;
          margin-top: 5px;
          background-color: $success;
          padding-#{$end}: 56px;
        }
      }

      th, td:not(:first-child) span {
        font-weight: 500;
      }

      thead {
        th {
          color: $primary-dark;
        }
      }

      tbody {
        tr {
          td:first-child {
            padding-#{$end}: 56px;
          }

          &:not(:last-child) {
            td span {
              padding: 0;
            }
          }

          &:nth-child(2) {
            td {
              padding-top: 16px;
            }
          }

          &:first-child {
            border-top: 1px solid $gray-lighter;
            border-bottom: 1px solid $gray-lighter;
          }
        }
      }
    }
  }

  .ActionFeedback {
    @include cancel-plan-slide('.ActionFeedback');

    &-title {
      @include h1-figma;
    }

    .Form {
      margin-top: 40px;

      .Radio, .InputField {
        padding: 0 64px 0 64px;
      }

      .Radio + .InputField {
        margin-top: 16px;
      }
    }

    &-actions {
      button:first-child {
        @include button-outline;
        padding: 20px 16px;
        display: none;
      }
    }
  }
}
