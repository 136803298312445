.Register {
  @include mobile {
    padding-bottom: 82px;
  }

  &-headerContainer {
    background-color: $primary-lighter;
  }

  &-formContainer {
    @extend .pb-m-3, .px-m-3;
    background-color: $primary-lighter;
  }

  .CvoFormBuilder {
    &-fieldset {
      margin-bottom: 0;
    }

    &-fieldsetInner {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    &-title {
      min-width: 100%;
    }

    &-description {
      min-width: 100%;
    }

    &-group {
      min-width: 50%;
      @include mobile {
        width: 100%;
        margin: 0;
        .CvoInputField {
          padding: 10px 0px;
        }
      }
    }

    &-actions {
      @include mobile {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }

  .CvoForm-field--error {
    position: absolute;
  }

  &-submit {
    display: block;
    margin: 0 auto;

    @include mobile {
      display: none;
    }
  }

  .VerificationTip {
    background-color: $white;
    padding: 20px;

    p {
      margin-bottom: 12px;
    }
  }

  a {
    color: $primary-dark;
    font-weight: 500;
  }
}
