.Mastercard {
  width: 100%;
  
  .columns {
    &:first-child,
    &:nth-child(3) {
      iframe {
        width: 100% !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:nth-child(2) {
      display: flex;

      @include mobile {
        display: block;
      }

      .is-two-thirds {
        width: 400px;
        flex-grow: unset;
        
        @include mobile {
          width: 100%;
          margin-bottom: 0;
          flex-grow: unset;
        }

        iframe {
          width: 50% !important;
        }

        & + .column {
          padding-left: 15px;
        }
      }
    }

    .column {
      position: relative;
      margin-bottom: 6px;
      &:nth-child(2) {
        iframe {
          width: 100% !important;
        }
      }
    }
  }

  p {
    @include text-s-figma;
    color: $gray-darker;
    margin-top: 15px;
  }


  &-label {
    @include text-s-figma;
    position: absolute;
    top: calc(50% - 10px);
    z-index: 2;
    transition: transform 200ms ease-in, font-size 200ms ease-in;
    margin-#{$start}: 12px;

    &.focus {
      transform: translateX(6.25px * $transform-to-start) translateY(-20px) scale(0.9);
      transition: transform 200ms ease-in-out, font-size 200ms ease-in-out;
    }
    &.invalid {
      color: $danger;
    }
  }

  input {
    border-radius: 0;
    height: 56px;
  }

  &-input,
  &-monthInput,
  &-yearInput {
    width: 100%;
    padding-top: 1.5rem;

    &.invalid {
      border: 1px solid $danger;
    }
  }

  &-expiryDateInput {
    display: flex;
    border: 1px solid $gray-light;
    padding: 1px;

    &.invalid {
      border: 1px solid $danger;
      .Mastercard-invalidField {
        top: 67px;
      }
    }
  }

  &-monthInput,
  &-yearInput {
    border: none;
  }

  &-slash {
    position: absolute;
    color: $gray-light;
    left: calc(50% - 4px);
    top: 35px;
    width: 1rem;
    height: 1rem;
  }
  
  &-invalidField {
    font-size: 0.75rem;
    position: absolute;
    top: 65px;
    left: 13px;
    color: $danger;
    width: 100%;
  }
}