html,
body {
  background-color: $white;
  font-family: $bukra;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 500;
}

.SiteWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  main {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div:not(.Header) {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div:not(.Header) {
        flex: 1;
      }

      .PublicPage {
        max-width: 1164px;
        width: 100%;
        margin: auto;
        padding-top: 62px;
      }
    }
  }
}

.PageContent {
  margin: 0 auto;
  max-width: 100%;
  width: $desktop;
  padding: 20px 32px;

  @include mobile {
    padding: 20px 20px;
  }

  .AccountPage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.PrivateSection {
  &Title {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px 0;
  }

  &Notifications {
   border: none;
  }
}

.PrivatePage {
  &-inner {
    display: flex;
    flex-direction: column;
  }

  &-contentWrapper {
    background-color: $primary-lighter;
    flex: 1;
    padding: 30px 0;
  }

  &-title {
    @include h3-figma;
    margin-bottom: 20px;

    @include mobile {
      text-align: center;
    }
  }
}

.Footer {
  margin-top: auto;
}

.is-loading.default-loader:before {
  background: hsla(0,0%,100%,.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.is-loading.default-loader:after {
  position: fixed;
}

[v-cloak] {
  display: none !important;
}