.InvoiceEdit {
  &-list {
    margin-bottom: 12px;
  }

  &-infoDescription {
    margin-bottom: 12px;
  }

  &-infoOptional {
    margin-bottom: 24px;
  }

  &-preview {
    margin-bottom: 24px;
  }

  &-input {
    margin-bottom: 24px;
  }

  &-actions {
    display: flex;
    gap: 12px;

    @include mobile {
      flex-direction: column;
    }
  }
}
