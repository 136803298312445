.MastercardEdit {
  @extend .CvoInformationCard;
  border-top: none;
  padding-top: 0;

  max-width:  $tablet + 131px;
  margin-bottom: 175px;
  margin: 0 auto;
  position: relative;

  @include tablet-down {
    max-width: $tablet - 100px;
  }

  &-cardDisplay {
    width: 300px;
    background: lightgray;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }

  &-cardLogo {
    text-align: right;

    span {
      font-weight: bold;
    }
  }

  &-cardChip {
    svg {
      width: 35px;
      fill: #e8c781;
      box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &-cardNumber {
    font-family: monospace;
    font-size: 18px;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 10px;
    /* Fix added space */
    height: 1.15em;
    line-height: 1.15;
  }

  &-cardNumber:after {
    /* Justify last line */
    content: "";
    display: inline-block;
    width: 100%;
  }

  &-cardDisplayRow {
    display: flex;
  }

  &-cardDisplayRow dl:first-child {
    flex: 1;
  }

  &-itemTitle {
    font-size: 12px;
  }

  &-editWrapper {
    // @extend .CvoInformationCard;
    // border-bottom: none;
    // border-top: none;
    // padding-top: 0;
  }

  &-actionsWrapper {
    // @extend .CvoInformationCard;
    // border-top: none;
    // padding-top: 0;
    
    .column {
      padding-left: 0;

      &:first-child {
        padding-top: 0;
        padding-bottom: 0;
        color: $gray-darker;
      }

      &:last-child {
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 65px;
        width: 100%;

        button {
          margin-bottom: 15px;
          width: 200px;
          padding-top: 10px;
          padding-bottom: 10px;

          &:last-child {
            background: $white;
            border: 1px solid $primary-darker;

            &:hover {
              color: $primary-darker;
            }
          }
        }
      }
    }
  }


  .CvoCheckbox {
    &-wrapper {
      align-items: center;

      & > label {
        span {
          @include text-s-figma;
          color: $gray-darker;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

  }
}
.hidden {
  display: none;
}
