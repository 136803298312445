.SimpleCart {
  display: flex;
  flex-direction: column;

  &-section {
    background: lightgray;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  &-titleWrapper {
    display: flex;
    justify-content: space-between;
  }

  &-title,
  &-passengerText,
  &-totalText,
  &-totalAmount {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  &-itemName {
    font-size: 14px;
    margin: 0;
    color: gray;
    margin-top: 10px;
  }

  &-item:first-child {
    .SimpleCart-itemName {
      margin-top: 0;
    }
  }

  &-couponForm {
    display: flex;
  }

  &-couponInput {
    flex: 1;

    &.is-invalid {
      border-color: red;
    }
  }

  &-amountColumn {
    text-align: right;
  }

  &-couponError {
    color: red;
    margin: 5px 0;
    font-size: 14px;
  }

  &-passengerInfo {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &-modalButton {
    align-self: flex-end;
  }

  &-arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    display: inline-block;

    &--down {
      border-top: 6px solid #000;
    }

    &--up {
      border-bottom: 6px solid #000;
    }
  }

  &-collapseButton {
    display: none;
  }

  @include mobile {
    &-collapseButton {
      display: inline-block;
    }

    .is-collapsed {
      display: none;
    }
  }
}

[dir="rtl"] {
  .SimpleCart {
    &-amountColumn {
      text-align: left;
    }
  }
}
