.SelectField {
  div.help {
    margin-top: 0;
  }

  .Form-field,
  .Form-field.select {
    height: auto;
  }

  option[disabled] {
    display: none;
  }

  .select {
    height: auto;

    &::after {
      pointer-events: none;
    }
  }
}

[dir="rtl"] {
  .SelectField {
    .select,
    .select:not(.is-multiple):not(.is-loading) {
      &::after {
        left: 1.125em;
        right: unset;
      }

      select {
        padding-left: 2.5em;
        padding-right: calc(0.75em - 1px); // reset default;
      }
    }
  }
}
