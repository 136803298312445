.DefinitionList {
  &-item {
    display: flex;
    justify-content: space-between;
  }

  &-definitionGroup {
    text-align: right;
  }

  &-list {
    display: grid;
    grid-gap: 4px 16px;
    grid-template-columns: max-content;
  }
}
