@keyframes delay-overflow {
  from { overflow: hidden; }
}

.StationsFilter {
  &-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-in-out;

    &.is-expanded {
      grid-template-rows: 1fr;

      .StationsFilter-contentInner {
        overflow: visible;
        animation: 1.5s delay-overflow;
      }
    }

    @include mobile {
      padding: 16px;
      transform: translateY(-48px);
    }
  }

  &-contentInner {
    overflow: hidden;
  }

  &-header {
    display: flex;
    gap: 32px;
    align-items: baseline;

    @include mobile {
      flex-direction: column-reverse;
      gap: 0;
    }
  }

  &-toggle {
    display:flex;
    gap: 0;

    @include mobile {
      position: relative;
      z-index: 1;
    }
  }

  &-toggleButton {
    margin-#{$start}: auto;

    @include mobile {
      margin-#{$start}: unset;
      margin-#{$end}: auto;
    }
  }

  &-actions {
    @include mobile {
      margin-#{$start}: auto;
    }
  }

  &-description {
    flex: 1;
    margin-bottom: 12px;
  }

  &-fieldsWrapper {
    display: flex;
    flex: 1;
  }

  .Autocomplete {
    width: 100%;
  }
}
