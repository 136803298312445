.DateField {
  &-selectContainer {
    display: flex;

    > div {
      flex: 1;
      margin-left: 10px;
      margin-right: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .SelectField {
    .Form-field--error {
      display: none;
    }
  }
}

[dir="rtl"] {
  .DateField {
    &-selectContainer {
      > div {
        &:first-child {
          margin-left: 10px;
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
}
