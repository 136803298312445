.MultiOptionPlanContainer {
  display: flex;
  justify-content: space-between;
  max-width: $desktop;
  margin: auto;

  @include tablet-down {
    flex-wrap: wrap;

    .CvoMultiOptionPlan {
      width: 48%;
      margin-bottom: 25px;
    }
  }

  @include mobile {
    .CvoMultiOptionPlan {
      width: 100%;
    }
  }
}

.CvoMultiOptionPlan {
  background-color: $white;
  border-radius: $border-radius;
  border: 2px solid $gray-lighter;
  display: flex;
  flex-direction: column;
  padding: 6px;

  &-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-bottom: 16px;
  }

  &-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px;
  }

  &-title {
    color: $primary-dark;
    margin-bottom: 20px;
    text-align: $start;
  }

  &-description {
    @include text-s-figma;
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    &::before {
      background-color: $primary-dark;
      content: '';
      display: inline-block;
      height: 35px;
      margin-#{$end}: 6px;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      width: 35px;
    }
  }

  .CvoForm {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div {
      display: flex;
      flex: 1;
      flex-direction: column;

      > form {
        flex: 1;
        flex-direction: column;
        display: flex;
      }
    }
  }

  &-submitButton {
    @include button-outline;
    margin-top: auto;
    padding: 12px 14px;
  }

  &-priceContainer {
    display: none;
  }

  &-bottomSlotText {
    @include text-s-figma;

    display: flex;

    &::before {
      background-color: $primary-dark;
      content: '';
      display: inline-block;
      height: 30px;
      margin-#{$end}: 6px;
      mask-image: url($icons-sprite + '#account');
      mask-position: top;
      mask-repeat: no-repeat;
      mask-size: contain;
      min-height: 30px;
      min-width: 30px;
      width: 30px;
    }
  }

  &-discounts {
    background-color: $gray-lightest;
    margin-left: -12px;
    margin-right: -12px;
    padding-top: 15px;
    padding-#{$start}: 12px;
    padding-#{$end}: 12px;
    display: flex;
    gap: 5px;

    span {
      @include text-xxs-figma;
      font-size: 10px;
      padding: 6px 8px;
      border-radius: 100px;
      font-weight: 500;
    }

    span:first-of-type {
      background: $secondary;
      color: $primary-dark;

      &::before {
        content: '';
        mask-image: url($icons-sprite + '#discount');
        display: inline-block;
        height: 9px;
        width: 16px;
        background-color: $primary-dark;
        mask-position: top;
        mask-repeat: no-repeat;
        mask-size: contain;
        scale: 2;
        margin-#{$end}: 3px;
      }
    }
  }

  .slot {
    background-color: $gray-lightest;
    margin-left: -12px;
    margin-right: -12px;
    padding: 15px 28px 0px;
  }

  .CvoRadio {
    background-color: $gray-lightest;
    margin-bottom: 28px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 15px 12px;

    .control {
      align-items: flex-start;
    }

    label {
      @include h3-figma;

      align-items: center;
      color: $primary-dark;
      display: flex;
      flex-wrap: wrap;
      justify-content: #{$start};
      gap: 10px;

      > span {
        @include text-s-figma;
        display: inline-block;
      }

      .discount {
        text-decoration: line-through;
        color: $primary-dark;
      }

      .tag {
        @include text-xxs-figma;

        background: $secondary;
        border-radius: 100px;
        color: $primary-dark;
        display: inline-block;
        font-weight: 500;
        padding: 6px 8px;
      }

      .notice {
        @include text-xxs-figma;

        width: 100%;
      }
    }
  }
}

.CvoMultiOptionPlan--disabled {
  .CvoMultiOptionPlan {
    &-submitButton {
      display: none;
    }
  }
}

.CvoMultiOptionPlan--selected {
  position: relative;
  
  .CvoMultiOptionPlan {
    &-selectedTag {
      @include text-s-figma;

      background-color: $primary;
      border-radius: 16px;
      color: $white;
      font-weight: 500;
      padding: 6px 12px;
      position: absolute;
      top: 12px;
      #{$start}: 12px;
    }
  }
}

.CvoMultiOptionPlan--SINGLE {
  .CvoMultiOptionPlan {
    &-description {
      &::before {
        mask-image: url($icons-sprite + "#account");
      }
    }
  }
}

.CvoMultiOptionPlan--DUO {
  .CvoMultiOptionPlan {
    &-description {
      &::before {
        mask-image: url($icons-sprite + "#two-members");
      }
    }
  }
}

.CvoMultiOptionPlan--FAMILY4 {
  .CvoMultiOptionPlan {
    &-description {
      &::before {
        mask-image: url($icons-sprite + "#five-members");
      }
    }
  }
}

.CvoMultiOptionPlan--FAMILY8 {
  .CvoMultiOptionPlan {
    &-description {
      &::before {
        mask-image: url($icons-sprite + "#eight-members");
      }
    }
  }
}
