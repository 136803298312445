.VerticalPlan {
  width: 300px;

  &-container {
    padding: 1em;
    width: 100%;
  }

  &-title {
    font-size: 2em;
    text-align: center;
  }

  &-link {
    clear: both;
    display: block;
    cursor: pointer;
    text-align: right;

    &::after {
      content: " >";
    }
  }

  &-price {
    display: block;
    font-size: 2.4em;
    text-align: center;
  }

  &-submitButton {
    cursor: pointer;
    width: 100%;
  }
}

[dir="rtl"] {
  .VerticalPlan {
    &-link {
      text-align: left;
    }
  }
}