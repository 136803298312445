.MultiOptionPlan {
  width: 300px;
  padding: 1em;

  &-title {
    font-size: 2em;
    text-align: center;
  }

  &-link {
    clear: both;
    display: block;
    cursor: pointer;
    text-align: right;

    &::after {
      content: ' >';
    }
  }

  &-priceContainer {
    display: block;
    font-size: 2.4em;
    text-align: center;
  }

  &-submitButton {
    cursor: pointer;
    width: 100%;
  }
}

[dir="rtl"] {
  .MultiOptionPlan {
    &-link {
      text-align: left;
    }
  }
}