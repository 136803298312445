@mixin button-disabled {
  background-color: $gray-lighter;
  border-color: $gray-lighter;
  color: $gray;
  opacity: 1;
  pointer-events: none;
  user-select: none;
}

@mixin button-base {
  border: 1px solid;
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: $bukra;
  font-size: 16px;
  font-weight: 500;
  height: auto;
  text-decoration: none;

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &.disabled {
    @include button-disabled;
  }

  &:active,
  &:focus,
  &:focus:not(:active) {
    box-shadow: none;
    outline: none;
  }
}

@mixin button-lg {
  font-size: 20px;
  line-height: 30px;
  padding: 20px 16px;
}

@mixin button-sm {
  font-size: 14px;
  line-height: 21px;
  padding: 12px 8px;
}

@mixin button-primary {
  @include button-base;

  background-color: $primary-light;
  border-color: $primary-light;
  color: $white;
  line-height: 24px;
  padding: 16px 14px;

  &:hover {
    background-color: $primary-dark;
    border-color: $primary-dark;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $primary-light;
    border-color: $primary-light;
    color: $white;
  }
}

@mixin button-primary-lg {
  @include button-primary;
  @include button-lg;
}

@mixin button-primary-sm {
  @include button-primary;
  @include button-sm;
}

@mixin button-secondary {
  @include button-base;

  background-color: $secondary;
  border-color: $secondary;
  color: $primary-darker;
  line-height: 24px;
  padding: 16px 14px;

  &:hover {
    background-image: $primary-gradient;
    border-color: $primary-dark;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $secondary;
    background-image: none;
    border-color: $secondary;
    color: $primary-darker;
  }
}

@mixin button-secondary-lg {
  @include button-secondary;
  @include button-lg;
}

@mixin button-secondary-sm {
  @include button-secondary;
  @include button-sm;
}

@mixin button-outline {
  @include button-base;

  background-color: $white;
  border-color: $primary-darker;
  color: $primary-darker;
  line-height: 24px;
  padding: 16px 14px;

  &:hover {
    background-image: $primary-gradient;
    border-color: $primary-dark;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $primary-darker;
    border-color: $primary-darker;
    color: $white;
  }
}

@mixin button-outline-lg {
  @include button-outline;
  @include button-lg;
}

@mixin button-outline-sm {
  @include button-outline;
  @include button-sm;
}

@mixin button-danger {
  @include button-base;

  background-color: $danger;
  border-color: $danger;
  color: $white;
  line-height: 24px;
  padding: 16px 14px;

  &:hover {
    background-color: $danger;
    background-image: none;
    border-color: $danger;
    color: $white;
  }

  &:active,
  &:focus {
    background-color: $danger;
    border-color: $danger;
    color: $white;
  }
}

@mixin button-danger-lg {
  @include button-danger;
  @include button-lg;
}

@mixin button-danger-sm {
  @include button-danger;
  @include button-sm;
}

@mixin button-transparent {
  @include button-base;

  border: none;
  background-color: unset;
  color: $primary-dark;
  align-items: center;
  height: unset;
  margin-bottom: 10px;
  padding-top: 0px;
  gap: 8px;

  &:hover {
    color: $primary-light;
    background-image: none;
    border-color: unset;
    background-color: unset;
  }

  &:active,
  &:focus {
    background-color: unset;
    background-image: none;
    border-color: unset;
  }
}

@mixin button-icon($icon, $color) {
  background-color: $color;
  content:  '';
  display: inline-block;
  height: 24px;
  margin-#{$end}: 12px;
  mask-image: url($icons-sprite + '#' + $icon);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
  width: 24px;
}

@mixin button-icon-before($icon, $color: $gray-dark, $color-hover: $gray-dark, $color-active: $gray-dark) {
  align-items: center;
  display: flex;

  &::before {
    @include button-icon($icon, $color);
  }

  &:hover {
    &::before {
      background-color: $color-hover;
    }
  }

  &:active,
  &:focus {
    &::before {
      background-color: $color-active;
    }
  }
}

@mixin button-icon-after($icon, $color: $gray-dark, $color-hover: $gray-dark, $color-active: $gray-dark) {
  align-items: center;
  display: flex;

  &::after {
    @include button-icon($icon, $color);
  }

  &:hover {
    &::before {
      background-color: $color-hover;
    }
  }

  &:active,
  &:focus {
    &::before {
      background-color: $color-active;
    }
  }
}

@mixin add-button($text-color: $white, $button-color: $primary-darker, $border-color: $primary-darker) {
  @include text-s-figma;
  line-height: normal;
  background-color: $button-color;
  color: $text-color;
  border: 1px solid $border-color;
  font-weight: 500;

  &:disabled {
    color: $gray-light;
    border-color: $gray-light;
  }

  &::before {
    content: '+';
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 10px;
    border: 1px solid $text-color;
    font-weight: 400;
    margin-#{$end}: 12px;
    transform: translateY(-1px);
  }
}

@mixin button-wide {
  padding-left: 64px;
  padding-right: 64px;
}

@mixin button-wide-lg {
  padding-left: 80px;
  padding-right: 80px;
}

@mixin button-wide-sm {
  padding-left: 48px;
  padding-right: 48px;
}

button,
.button {
  @include button-secondary;

  &--sm {
    @include button-sm;
  }

  &--lg {
    @include button-lg;
  }

  &--transparent {
    @include button-transparent;
  }
}

.button-wide {
  @include button-wide;
}
