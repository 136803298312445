.InvoicesTable {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  .Table {
    &-noResults {
      width: 100%;
      display: flex;
      justify-content: center;

      > div {
        display: flex;
      }
    }
  }
}