@mixin mobile {
  @media screen and (max-width: calc(#{$tablet} - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-down {
  @media screen and (max-width: calc(#{$desktop} - 1px)) {
    @content;
  }
}


@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: calc(#{$desktop} - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
