.FlightDateSelector {
  .CarouselSlider-carousel {
    background: white;
  }

  .CarouselSlider-slideInner {
    border: 1px solid lightgray;
  }

  &-selectedDate {
    .CarouselSlider-slideInner {
      background-color: lightblue;
    }
  }

  &-disabled {
    .CarouselSlider-slideInner {
      background-color: whitesmoke;
      color: lightgray;
    }
  }
}