.InvoiceDisplay {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
  }

  &-invoice {
    width: 65%;
    border: 1px solid #000000;
    
    @include mobile {
      width: 100%;
      overflow: auto;
    }
  }

  &-sideBar {
    width: 35%;

    @include mobile {
      width: 100%;
    }
  }

  &-iframe {
    width: 100%;
    min-height: 700px;

    @include mobile {
      min-width: 600px;
    }
  }

  &-download {
    margin-bottom: 32px;
    width: 100%;
  }
}