$spacing: 30px;
.CvoModal {
  .CvoClose {
    top: 5px;
    #{$end}: 5px;
  }

  &-title {
    padding: 35px $spacing calc(#{$spacing} / 2);

    h1 {
      text-align: center;

      i::before {
        height: 42px;
        width: 42px;
        margin-#{$end}: 10px;
        display: inline-block;
        transform: translateY(12px);
      }
    }
  }

  &-content {
    padding: calc(#{$spacing} / 2) $spacing $spacing;

    p {
      text-align: center;
    }
  }

  &-actions {
    display: flex;
    justify-content: center;
    margin-top: $spacing;
  }

  &--notification {
    .vm--modal {
      height: auto !important; // override inline set programatically
      max-width: 480px;
      left: unset !important; // override inline set programatically
      right: unset !important; // override inline set programatically
      margin: auto;

      @include mobile {
        max-width: 100%;
      }
    }
  }
}

