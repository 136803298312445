.Notification {
  &-inner {
    padding: $container-padding-sm;
  }

  &-closeButton {
    display: flex;
    top: $container-padding-sm;
    height: $button-size;
    padding: 0;
    position: absolute;
    width: $button-size;
    #{$end}: $container-padding-sm;

    .ActionButton-iconBefore {
      margin: auto;
    }
  }

  &.has-icon {
    .Notification {
      &-inner {
        display: flex;
      }

      &-icon {
        margin-#{$end}: 24px;
      }
    }
  }

  &.has-close {
    .Notification {
      &-inner {
        padding-#{$end}: calc(#{$button-size} + calc(#{$container-padding-sm} * 2));
        position: relative;
      }
    }
  }

  &--info {
    .subs-icons::before {
      background-color: $primary;
    }
  }

  &--success {
    .subs-icons::before {
      background-color: $success;
    }
  }

  &--danger {
    .subs-icons::before {
      background-color: $danger;
    }
  }

  &--warning {
    .subs-icons::before {
      background-color: $warning;
    }
  }
}
