.SearchCombo {
  &-searchDisplay {
    display: none;

    @include mobile {
      display: flex;
    }
  }

  &-currentSearch {
    flex: 1;
  }

  &-currentRoute {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &-divider {
    flex: 1;
    border-top: 1px solid #000;
  }

  &-row {
    display: flex;
    align-items: flex-end;

    @include mobile {
      align-items: stretch;
      flex-direction: column;
    }
  }

  &-fieldsContainer {
    display: flex;

    @include mobile {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  &-fieldsContainer {
    flex: 1;
  }

  &-submit {
    @include mobile {
      justify-content: center;
    }
  }

  &-editHeader {
    display: none;
  }

  .Autocomplete,
  .Datepicker {
    flex: 1;
    margin-right: 10px;

    @include mobile {
      margin-right: 0;
    }
  }

  .Radio {
    .control {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .Autocomplete {
    input {
      &.is-success,
      &.is-danger,
      &.help,
      &:focus,
      &:active {
        border-color: #dbdbdb;
        box-shadow: none;
      }
    }
  }

  &--withDisplay {
    .SearchCombo {
      &-formWrapper {
        @include mobile {
          display: none;
        }
      }

      &-editHeader {
        @include mobile {
          display: block;
        }
      }
    }

    &.is-edit {
      .SearchCombo-formWrapper {
        @include mobile {
          background: white;
          display: block;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      .Overlay {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.Datepicker-datepicker-popup {
  .cell.selected-range {
    background: lightskyblue;
  }

  .cell.is-departure,
  .cell.is-return {
    background: black;
    color: white;
  }
}
