.Card-actions {
  display: flex;
  gap: 8px;
  padding: $container-padding-md;
  padding-top: 0;

  @include mobile {
    flex-direction: column;
    padding: $container-padding-sm;
  }  
}