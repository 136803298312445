.YourPlan {

  &-status {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    span {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      padding: 6px 8px;
    }

    &.disabled {
      border-top: 5px solid $gray;

      span {
        background-color: $gray;
      }
    }

    &.danger {
      border-top: 5px solid $danger;
      
      span {
        background-color: $danger;
      }
    }

    &.warning {
      border-top: 5px solid $warning;

      span {
        background-color: $warning;
      }
    }

    &.pendingCancellation {
      border-top: 5px solid $gray;

      span {
        background-color: $gray;
      }
    }
  }

  &.is-cancelled {
    .CvoInformationCard {
      &-title {
        color: $gray;
      }

      &-content {
        color: $gray-light;
      }
    }
  }
  
  .is-loading.default-loader {
    position: absolute;
  }
}