.SiteHeader {
  display: flex;
  justify-content: space-between;

  &-actions {
    display: flex;
    align-items: center;
    position: relative;
  }

  &-languageContainer {
    margin-right: 10px;
  }

  &-userPanel {
    position: absolute;
    right: 0;
    display: none;

    &.is-visible {
      display: block;
    }
  }

  &-logoutButton {
    display: block;
  }

  &-closeLoginPanelButton {
    display: none;
    font-family: monospace;
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
      display: block;
    }
  }
}
